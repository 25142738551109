@mixin jost-font-face($style, $weight: 400) {
  @font-face {
    font-family: 'Jost';
    src: url("/assets/fonts/jost/Jost-#{$style}.woff2") format('woff2');
    font-weight: $weight;
    font-style: if(index($style, 'Italic'), italic, normal);
    font-display: swap;
  }
}

@include jost-font-face('Light', 300);
@include jost-font-face('Semi');
@include jost-font-face('Book');
@include jost-font-face('Medium', 500);
@include jost-font-face('Bold', 700);

@font-face {
  font-family: 'Jam';
  src:  url('/assets/fonts/jam/Jam.eot?j7o824');
  src:  url('/assets/fonts/jam/Jam.eot?j7o824#iefix') format('embedded-opentype'),
    url('/assets/fonts/jam/Jam.ttf?j7o824') format('truetype'),
    url('/assets/fonts/jam/Jam.woff2?j7o824') format('woff2'),
    url('/assets/fonts/jam/Jam.svg?j7o824#Jam') format('svg');
  font-weight: normal;
  font-style: normal;
      font-display: swap;
}
@font-face { 
  font-family: 'IcoFont';
  src:  url('/assets/fonts/iconfont/icofont.eot');
  src:  url('/assets/fonts/iconfont/icofont.eot') format('embedded-opentype'),
    url('/assets/fonts/iconfont/icofont.ttf') format('truetype'),
    url('/assets/fonts/iconfont/icofont.woff2') format('woff2'),
    url('/assets/fonts/iconfont/icofont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
      font-display: swap;
}
@font-face {
    font-family: 'Snowlake';
    src: url('/assets/fonts/snowlake/Snowlake.eot');
    src: url('/assets/fonts/snowlake/Snowlake.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/snowlake/Snowlake.woff') format('woff'),
         url('/assets/fonts/snowlake/Snowlake.ttf') format('truetype'),
         url('/assets/fonts/snowlake/Snowlake.svg#Snowlake') format('svg');
    font-weight: normal;
    font-style: normal;
        font-display: swap;
}
[class^="c-"], [class*=" c-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Custom' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="jam-"], [class*=" jam-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Jam' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icofont-"], [class*=" icofont-"] {
  font-family: 'IcoFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-blob"], [class*=" icon-blob"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Snowlake' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-shape:before {
  content: "\0041";
}
.c-arrow-expand:before {
  content: "\e903";
}
.c-flower:before {
  content: "\e904";
}
.c-arrow-left:before {
  content: "\e900";
}
.c-arrow-right:before {
  content: "\e901";
}
.c-close:before {
  content: "\e902";
}