/*

/*-------------------------------------------------------------------------------------

01. RESET
02. GENERAL
03. NAVBAR
04. STICKY HEADER
05. MEGA MENU
06. HAMBURGER MENU ICON
07. OFFCANVAS
08. SEARCH
09. CART
10. BUTTON
11. BOX
12. WRAPPERS
13. IMAGE BACKGROUND WRAPPER
14. IMAGE BLOCK WRAPPER
15. VIDEO BACKGROUND WRAPPER
16. SHADOW
17. ROUNDED CORNERS
18. CIRCLE
19. DIVIVDER
20. DROPCAP
21. BADGE
22. ALERT
23. MODAL
24. BLOCKQUOTE
25. LISTS
26. SERVICES
27. CLIENTS
28. FIGURE
29. OVERLAY
30. TILES
31. BLOG
32. WIDGETS
33. PAGINATION
34. BREADCRUMBS
35. COMMENTS
36. DIAL
37. PROGRESS
38. COUNTER
39. COUNTDOWN
40. TABS
41. ACCORDION / COLLAPSE
42. TABLES
43. PRICING
44. SHOP
45. RATING
46. TOOLTIP
47. POPOVER
48. COLUMNS & GUTTERS
49. SLIDER REVOLUTION
50. OWL CAROUSEL
51. ISOTOPE
52. PLYR
53. SOCIAL ICONS
54. FORMS
55. LIGHTGALLERY
56. SCROLL ANIMATIONS
57. LOADING
58. FONT ICONS LIST
59. LINEAR ICONS LIST
60. SPLIT LAYOUT
61. COLORS
62. INVERSE TEXT
63. BOX LAYOUT
64. RESPONSIVE
65. NYCKEL ADDED
-------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------*/
/*  01. RESET
/*-----------------------------------------------------------------------------------*/
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus {
    outline: 0
}

.fit-vids-style {
    display: none
}

iframe {
    border: none
}

.clearfix {
    clear: both;
}

::-moz-selection {
    background: rgba(var(--rgb-default), 0.7);
    color: var(--color-white);
}

::selection {
    background: rgba(var(--rgb-default), 0.7);
    color: var(--color-white);
}

.bg-default ::-moz-selection {
    background: var(--color-white);
    color: #606060;
}

.bg-default ::selection {
    background: var(--color-white);
    color: #606060;
}

br {
    line-height: 0;
}

button:focus {
    outline: none !important;
}

.logo-light {
    width: 180px !important;
    height: 27px !important;
}

.logo-dark {
    width: 100px !important;
    height: 40px !important;
}

.logo-dark {
    width: 180px !important;
    height: 27px !important;
}

.author-image {
    width: 300px !important;
    height: 300px !important;
}

/*-----------------------------------------------------------------------------------*/
/*  02. GENERAL
/*-----------------------------------------------------------------------------------*/
html {
    font-size: 20px;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Jost', sans-serif;
    color: #606060;
    font-size: 0.8rem;
    background: #f9fafb;
    overflow-x: hidden;
    position: relative;
    font-display: swap;
}

.container {
    padding-right: 0;
    padding-left: 0;
}

.container-fluid {
    padding-left: 20px;
    padding-right: 20px
}

.award-image img {
    margin-bottom: 20px;
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
}

.award-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar .container-fluid {
    padding-left: 30px;
    padding-right: 30px
}

body,
li,
address {
    line-height: 1.4rem;

}

input,
button,
select,
textarea {
    font-family: inherit;
    font-weight: normal;
    color: inherit;
}

strong {
    font-weight: bold;
}

p {
    margin: 0 0 1rem
}

.hover {}

a.hover {

    padding-bottom: 0;
    border-bottom: 1px solid transparent;
}

a.hover:hover {}

a {
    color: var(--color-default);

}

.post-title a,
a.nocolor {
    color: inherit;
}

a:hover,
a:focus {
    text-decoration: none;
    color: var(--color-default);
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

strong {
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    color: #404040;
    font-weight: 500;
    letter-spacing: normal;
    margin-bottom: 0.8rem;
}

h1,
.h1 {
    font-size: 1.4rem;
    line-height: 1.7rem;
}

h2,
.h2 {
    font-size: 1.3rem;
    line-height: 1.6rem;
}

h3,
.h3 {
    font-size: 1.2rem;
    line-height: 1.5rem;
}

h4,
.h4 {
    font-size: 1.1rem;
    line-height: 1.4rem;
}

h5,
.h5 {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.6rem;
}

h6,
.h6 {
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin-bottom: 0.6rem;
}

h1.text-uppercase,
.h1.text-uppercase {
    font-size: 1.2rem;
    line-height: 1.5rem;
}

h2.text-uppercase,
.h2.text-uppercase {
    font-size: 1.1rem;
    line-height: 1.4rem;
}

h3.text-uppercase,
.h3.text-uppercase {
    font-size: 1rem;
    line-height: 1.3rem;
}

h4.text-uppercase,
.h4.text-uppercase {
    font-size: 0.9rem;
    line-height: 1.2rem;
}

h5.text-uppercase,
.h5.text-uppercase {
    font-size: 0.8rem;
    line-height: 1.1rem;
}

h6.text-uppercase,
.h6.text-uppercase {
    font-size: 0.7rem;
    line-height: 1rem;
}

.grid-view h2.post-title {
    margin-bottom: 1rem;
    font-size: 1.1rem;
    line-height: 1.4rem;
}

.classic-view h2.post-title,
.caption-overlay h3 {
    font-size: 1.4rem;
    line-height: 1.7rem;
}

.widget-title {
    font-size: 1rem;
    line-height: 1.3rem;
}

.section-title {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: 1rem;
}

.section-title.larger {
    font-size: 1.8rem;
    line-height: 2.1rem;
}

.section-title.text-uppercase {
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin-bottom: 1rem;
}

.section-title.larger.text-uppercase {
    font-size: 1.2rem;
    line-height: 1.5rem;
}

.title-color {
    font-size: 0.8rem;
    line-height: 1.1rem;
    margin-bottom: 1.05rem;
    text-transform: uppercase;
}

.title-bg {
    font-size: 0.55rem;
    line-height: 1rem;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 10rem;
    padding: 0.35rem 0.7rem 0.3rem;
}

.page-title {
    font-size: 2.5rem;
    line-height: 2.8rem;
    margin-bottom: 1rem;
}

.hero {
    font-size: 3rem;
    line-height: 3.6rem;
    font-weight: 500;
}

.display-1 {
    font-size: 2.5rem;
    line-height: 3.1rem;
    font-weight: 500;
}

.display-custom2 {
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 500;
}

.display-custom {
    font-size: 2.7rem;
    line-height: 3rem;
    font-weight: 500;
}

.display-custom3 {
    font-size: 2.2rem;
    line-height: 2.7rem;
    font-weight: 500;
}


.display-2 {
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 500;
}

.display-mockup {
    font-size: 1.5rem;
    line-height: 1.7rem;
    font-weight: 500;
    font-family: Arial, sans-serif !important;
    color: #006400;
}

.display-3 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: normal;
}

.display-4 {
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 300;
}

.display-5 {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 200;
}

.lead {
    font-size: 1rem;
    line-height: 1.5rem;
}

.page-title-wrapper .lead {
    font-size: 1.1rem;
    line-height: 1.6rem;
}

.lead.larger {
    font-size: 1.2rem;
    line-height: 1.7rem;
}

.text-muted {
    color: #808080 !important;
}

.inner {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.inner-top {
    padding-top: 6rem;
}

.inner-bottom {
    padding-bottom: 6rem;
}

footer .inner {
    padding-bottom: 5rem;
}

.page-title-wrapper .inner {
    padding-top: 5rem;
    padding-bottom: 0;
}

.page-title-wrapper.image-wrapper .inner {
    padding-bottom: 4rem;
}

.google-map iframe {
    display: block;
}

.google-map.map-full {
    height: 100%;
}

#wave {
    margin: 0;
}

canvas {
    vertical-align: bottom;
}

.divider svg {
    vertical-align: bottom;
}

.pointer-none {
    pointer-events: none;
}


.dark-section {

    background-color: #0C0F44;
    flex: none;
    position: relative
}

.background-container {
    color: #fff;
    background-color: #0C0F44;
    flex: none;
    position: relative;
}

.dark-section.background-boxes {
    background-image: url(https://global-uploads.webflow.com/62bc9ad59059e52cf0d34a55/6303b84a04f5537a89c55857_boxes_l.svg), url(https://global-uploads.webflow.com/62bc9ad59059e52cf0d34a55/6303b84b04f5531023c55859_boxes_r.svg);
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat, no-repeat;
    background-size: 100% 100%, 100% 100%;
    margin-top: 0;
    padding-top: 40px;
    padding-bottom: 40px
}

.dark-section.background-boxes.height-500 {
    height: 500px;
    justify-content: flex-end;
    align-items: center;
    padding-top: 220px;
    display: block
}

.dark-section.background-boxes.team-sectiom {
    position: relative;
    overflow: visible
}

.content-container {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px
}

.section-padding {
    padding-left: 100px;
    padding-right: 100px;
}

.section-padding.no-margin,
.section-padding.no-margin.text-centered {
    font-size: 33px
}

.section-padding--v {
    padding-top: 40px;
    padding-bottom: 40px
}

.section-padding--v.no-margin.text-centered {
    font-size: 28px
}

.no-margin {
    margin-top: 0;
    margin-bottom: 0
}

.text-centered {
    text-align: center;
    font-family: Jost, sans-serif;
    font-size: 24px;
    line-height: 1.4em
}


.left-image,
.right-image {
    position: absolute;
    top: 0;
    height: 100%;
}

.left-image {
    left: 0;
    z-index: 1;
}

.right-image {
    right: 0;
    z-index: 1;
}

.faint.center-text.text-medium {
    text-align: center
}

.vimeo-video {
    width: 150%;
    /* Adjust the value to your preference */
    height: auto;
    /* Maintain aspect ratio */
    max-width: none;
    /* Override any other max-width styles */
}

.text-medium {
    font-size: 18px;
    line-height: 1.4em;
    padding-left: 100px;
    padding-right: 100px;
}

.text-medium.faint {
    margin-bottom: 20px;
    line-height: 1.4em
}

.text-medium {
    font-size: 18px
}

.dropdown-menu.hidden:empty {
    display: none;
}

/*-----------------------------------------------------------------------------------*/
/*  03. NAVBAR
/*-----------------------------------------------------------------------------------*/

.bump {
    margin-top: -100px;
}

.main-content {
    padding-top: 110px;
    /* Default padding for smaller screens */
}

@media (max-width: 768px) {
    .main-content {
        padding-top: 80px;
        /* Adjust for mobile navbar height */
    }
}

.navbar.fixed.banner--stick,
.navbar.shadow:not(.fixed):not(.banner--stick) {
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04)
}

.navbar-nav,
.navbar-nav * {
    line-height: 1;
}

.navbar .container {
    position: relative;
}

.navbar.absolute {
    position: absolute;
}

.navbar.navbar-bg-light {
    background: var(--color-white) !important;
}

.navbar .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
}

@media (max-width: 767px) {
    .navbar .navbar-brand {
        padding-left: 0 !important;
    }
}

.navbar .navbar-brand a {
    color: #303030;
}

.navbar-brand img {
    -webkit-transform: translateZ();
    height: auto;
    width: 140px;
    max-width: none;
}

.navbar.transparent .logo-dark {
    display: none;
}

.navbar.fixed.transparent .logo-light {
    display: none;
}

.navbar.fixed.transparent .logo-dark {
    display: inline-block;
}

.nav-fill .nav-item {
    text-align: left;
}

.nav-fill>.nav-item {
    text-align: center
}

.navbar-nav .nav-link {
    color: #0b2436;
}

.navbar-nav .nav-link,
.dropdown-item,
.mega-menu-content a {
    font-size: 0.85rem;
    font-weight: 500;
}

.nav-uppercase .navbar-nav .nav-link {
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.dropdown-menu {
    min-width: 425px;
    padding: 0;
    margin: 0;
    background: #1e2228;
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.dropdown-menu .dropdown-menu {
    border-radius: 0.2rem;
}

.navbar.extended:not(.banner--clone) .dropdown-menu,
.navbar.transparent:not(.banner--clone) .dropdown-menu {
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
}

.dropdown-menu .nav-item,
.mega-menu-content li {
    line-height: 1;
}

.dropdown-item,
.mega-menu-content a {
    padding: 0.6rem 1.2rem;
}

.mega-menu-content a {
    padding-left: 0;
    padding-right: 0;
    display: block;
}

.mega-menu-content .icon-list i {
    font-size: 0.7rem;
    width: 1.1rem;
}

.mega-menu-content a:hover {
    color: var(--color-white);
}

.dropdown-item.active {
    background: none;
    color: inherit;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.highlighted {
    background: none !important;
    border-top-color: transparent;
}

.dropdown-menu,
.dropdown-item,
.mega-menu-content a,
.dropdown-item:hover,
.dropdown-item:focus,
.mega-menu-content a:hover,
.mega-menu-content a:focus {
    color: var(--color-white);
}

.dropdown-toggle::after {
    display: none;
}

.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
    padding-right: 2rem;
}

.navbar-other .nav-item a:not(.btn),
.navbar-other .nav-item button {
    color: #303030;
    cursor: pointer;
}

.navbar-other .nav-item+.nav-item {
    padding-left: 0.8rem;
}

.navbar-other .nav-item+.nav-item.search-dropdown {
    padding-left: 1rem !important;
}

abbr[title],
acronym[title] {
    cursor: help !important;
    text-decoration: none !important;
}

.navbar:not(.extended) .topbar {
    font-size: 0.75rem;
    padding: 0.15rem 0;
}

/* Drop-down menu styling */
.navbar .dropdown-menu {
    background: #ffffff;
    /* Background color */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* Shadow effect */
    display: none;
    /* Initially hidden */
    position: absolute;
    /* Positioning relative to the parent element */
    width: auto;
    /* Width can be auto or fixed */
    z-index: 10;
    /* Ensure it's on top of other content */
}

/* Styling for drop-down items */
.navbar .dropdown-menu .dropdown-item {
    color: #303030;
    /* Text color */
    padding: 10px 20px;
    /* Padding for the drop-down items */
    text-decoration: none;
    /* No underline */
    display: block;
    /* Block display */
}

/* Hover effect for drop-down items */
.navbar .dropdown-menu .dropdown-item:hover {
    background-color: #306fa2;
    /* Background color on hover */
}

/* Show the drop-down menu on hover */
.navbar .nav-item:hover .dropdown-menu {
    display: block;
}

/*-----------------------------------------------------------------------------------*/
/*  04. STICKY HEADER
/*-----------------------------------------------------------------------------------*/
.navbar.fixed {
    margin: 0;
    width: 100%;
}

.banner--clone {
    position: fixed !important;
    z-index: 1008;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.banner--clone:not(.banner--stick) .dropdown-menu.search-dropdown-menu {
    display: none !important;
}

.banner--stick {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    background: rgba(var(--rgb-white), 0.95);
}

.banner--stick.bg-white {
    background: rgba(var(--rgb-white), 0.95) !important;
}

.banner--stick.navbar-fancy-dark {
    background: #242930 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.navbar.fixed:not(.extended) .topbar {
    display: none !important;
}

.navbar.fixed .navbar-collapse,
.navbar.fixed.navbar-fancy .container.bg-white {
    background: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.log {
    color: #0b2436 !important;
    font-size: 0.9em !important;
    white-space: nowrap !important;

}

/*-----------------------------------------------------------------------------------*/
/*  05. MEGA MENU
/*-----------------------------------------------------------------------------------*/
.navbar-nav,
.navbar-nav>li.mega-menu {
    position: static !important;
}

.navbar-nav>li>ul.mega-menu {
    margin-left: 0 !important;
    right: 0 !important;
    width: auto !important;
    max-width: none !important;
}

/*-----------------------------------------------------------------------------------*/
/*  06. HAMBURGER MENU ICON
/*-----------------------------------------------------------------------------------*/
button.plain {
    cursor: pointer;
    vertical-align: middle;
    border: 0 none;
    background: none;
    padding: 0;
    margin: 0;
}

button.plain::-moz-focus-inner {
    padding: 0;
    border: 0 none;
}

button.plain:focus {
    outline: 0;
}

button.hamburger {
    display: inline-block;
    width: 24px;
    height: 22px;
    padding: 1px 0 0 0;
}

button.hamburger:before,
button.hamburger:after {
    content: "";
}

button.hamburger:before,
button.hamburger span,
button.hamburger:after {
    display: block;
    width: 100%;
    height: 3px;
    margin: 0 0 5px;
    background: #303030;
}

.inverse-text:not(.fixed) button.hamburger:before,
.inverse-text:not(.fixed) button.hamburger span,
.inverse-text:not(.fixed) button.hamburger:after {
    background: var(--color-white);
}

button.hamburger.animate.active span {
    -ms-transform: scale(0);
    transform: scale(0);
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
}

button.hamburger.animate.active:before {
    -ms-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
    -moz-transform: translateY(8px) rotate(45deg);
    -webkit-transform: translateY(8px) rotate(45deg);
}

button.hamburger.animate.active:after {
    -ms-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
    -moz-transform: translateY(-8px) rotate(-45deg);
    -webkit-transform: translateY(-8px) rotate(-45deg);
}

/*-----------------------------------------------------------------------------------*/
/*  07. OFFCANVAS
/*-----------------------------------------------------------------------------------*/
.offcanvas-info {
    position: fixed;
    top: 0;
    bottom: 0;
    left: auto;
    right: -300px;
    width: 300px;
    padding-top: 3rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    overflow-y: auto;
    visibility: hidden;
    background: #1e2228;
    z-index: 1041;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.offcanvas-info.open {
    right: 0;
    visibility: visible;
}

.offcanvas-close {
    position: absolute;
    top: 0.5rem;
    left: 0.8rem;
    cursor: pointer;
    color: var(--color-white);
    font-size: 1.2rem;
}

.offcanvas-nav-close {
    left: auto;
    right: 0.5rem;
}

.offcanvas-header {
    padding: 3rem 0 2rem;
}

.offcanvas-header img {
    max-width: 100%;
    height: auto;
}

.offcanvas-info .widget {
    margin-bottom: 2rem;
}

/*-----------------------------------------------------------------------------------*/
/*  10. BUTTON
/*-----------------------------------------------------------------------------------*/
.btn {
    color: var(--color-white);
    background: var(--color-default);
    border: 0;
    margin-bottom: 0.5rem;
    margin-right: 0.25rem;
    padding: 0.9rem 1.3rem 0.8rem;
    font-size: .65rem;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    border-radius: 1.5rem;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: inline-block;
    background-color: #3877f6;
}

.btn-icon i {
    vertical-align: -1px;
}

.navigation .btn-icon i {
    vertical-align: 0;
}

.btn-icon-left i {
    margin-right: 0.25rem;
}

.btn-icon-right i {
    margin-left: 0.25rem;
}

.btn:focus {
    color: var(--color-white);
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
}

.btn:hover,
.btn:active,
.btn.active {
    color: var(--color-white);
    -webkit-box-shadow: var(--btn-hover-default);
    box-shadow: var(--btn-hover-default);
}

.btn.btn-white {
    color: #404040;
}

.text-center .btn {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.text-right .btn {
    margin-right: 0;
}

.btn-s {
    padding: 0.65rem 0.9rem 0.55rem;
}

.btn-l {
    padding: 1.05rem 1.5rem 1rem;
    font-size: 0.7rem;
}

.btn.fixed-width {
    width: 165px
}

.btn.btn-square {
    padding: 0.5rem;
}

.btn-submit {
    margin: 0
}

.btn.btn-blue3 {
    background-color: #8AA9D6;
}

.inverse-text .tag-list a.btn {
    background: #303339;
}

.inverse-text .tag-list a.btn:hover,
.inverse-text .tag-list a.btn:focus,
.inverse-text .tag-list a.btn:active,
.inverse-text .tag-list a.btn.active {
    color: var(--color-white);
    -webkit-box-shadow: 0 0 0 5px rgba(var(--rgb-white), 0.03);
    box-shadow: 0 0 0 5px rgba(var(--rgb-white), 0.03);
}

/*-----------------------------------------------------------------------------------*/
/*  12. WRAPPERS
/*-----------------------------------------------------------------------------------*/
.white-wrapper {
    background-color: #fff !important;
}

.light-wrapper {
    background-color: #f9fafb !important;
}

.green-wrapper {
    background: linear-gradient(to right, #5ea5b3, #3b7c85);
}

.gray-wrapper {
    background-color: #f3f4f7 !important;
}

.dark-wrapper {
    background-color: #242930 !important;
}

footer.dark-wrapper,
.footer-dark-wrapper {
    background-color: #1e2228 !important;
}

.pattern-wrapper {
    background: transparent repeat left top;
}

.gradient-wrapper {
    background-image: -o-linear-gradient(315deg, rgba(149, 74, 166, 1) 0%, rgba(114, 90, 238, 1) 100%);
    background-image: linear-gradient(135deg, rgba(149, 74, 166, 1) 0%, rgba(114, 90, 238, 1) 100%);
}

.fill-white-wrapper {
    fill: #fff;
}

.fill-green-wrapper {
    fill: #5ea5b3;
}

.fill-light-wrapper {
    fill: #f9fafb;
}

.fill-gray-wrapper {
    fill: #f3f4f7;
}

.fill-dark-wrapper {
    fill: #242930;
}

.fill-footer-dark-wrapper {
    fill: #1e2228;
}

.overflow-top {
    margin-top: -10rem;
    position: relative;
    z-index: 2;
}

.overflow-top.bleed-s {
    margin-top: -7.5rem;
}

.overflow-top.bleed-l {
    margin-top: -30rem;
}

.overflow-bottom {
    top: 0rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    z-index: 2;
    margin-bottom: -2rem;
}

/*-----------------------------------------------------------------------------------*/
/*  16. SHADOW
/*-----------------------------------------------------------------------------------*/
.shadow {
    -webkit-box-shadow: var(--shadow) !important;
    box-shadow: var(--shadow) !important;
}

.shadow-hover:hover {
    -webkit-box-shadow: var(--shadow-hover) !important;
    box-shadow: var(--shadow-hover) !important;
}

.no-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/*-----------------------------------------------------------------------------------*/
/*  17. ROUNDED CORNERS
/*-----------------------------------------------------------------------------------*/
.rounded {
    border-radius: 0 !important;
}

.rounded:not(.main),
figure.rounded:not(.main) span.bg,
figure.rounded:not(.main) img,
.rounded:not(.main).overlay figcaption,
.owl-carousel figure.rounded:not(.main) img,
.owl-carousel .item>img,
.google-map.rounded iframe {
    border-radius: 0.2rem !important;
}

figure.main.rounded span.bg,
figure.main.rounded img,
.main.rounded.overlay figcaption {
    border-top-left-radius: 0.2rem !important;
    border-top-right-radius: 0.2rem !important;
}

/*-----------------------------------------------------------------------------------*/
/*  19. DIVIVDER
/*-----------------------------------------------------------------------------------*/
hr {
    border: 0;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid rgba(var(--rgb-black), 0.1);
    padding-top: 3.5rem;
    margin-bottom: 3.5rem;
}

hr.hr-footer {
    padding-top: 0;
    margin-bottom: 0;
}

hr.dashed {
    border-bottom-width: 2px;
    border-style: dashed;
}

hr.dotted {
    border-bottom-width: 2px;
    border-style: dotted;
}

hr.double {
    border-bottom-width: 4px;
    border-style: double;
}

_:default:not(:root:root),
hr.double {
    border-bottom-width: 3px;
}

.divider-icon {
    border: 0;
    position: relative;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.divider-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.1);
}

.divider-icon:before,
.divider-icon:after {
    position: absolute;
    top: 50%;
    content: "";
    border-top: 1px solid rgba(var(--rgb-black), 0.1);
    width: calc(50% - 1.5rem)
}

.divider-icon:before {
    left: 0;
}

.divider-icon:after {
    right: 0;
}

.divider-heading {
    border: 0;
    position: relative;
    padding: 0;
    margin-bottom: 1rem;
    line-height: 1;
    color: var(--color-default);
    opacity: 0.25;
    text-align: center;
}

.divider-heading i {
    font-size: 1.4rem;
    line-height: 1;
}



/*-----------------------------------------------------------------------------------*/
/*  28. FIGURE
/*-----------------------------------------------------------------------------------*/
figure {
    margin: 0;
    padding: 0;
}

figure img {
    max-width: 100%
}

figure.cover img {
    width: 100%;
}

figure.cover {
    position: relative;
}

figure.cover .divider [class*="fill-"] {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
}


/*-----------------------------------------------------------------------------------*/
/*  43. PRICING
/*-----------------------------------------------------------------------------------*/
.panel.pricing {
    text-align: center;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pricing-wrapper [class*="col-"] {
    z-index: 1;
}

.pricing-wrapper [class*="col-"].popular {
    z-index: 3 !important;
}

.pricing-wrapper .panel.pricing.box {
    padding: 3rem 2rem;
}

.panel.pricing .icon-blob {
    text-align: center;
    margin: 0 auto;
}

.panel.pricing .panel-heading {
    padding: 0;
}

.panel.pricing .panel-title {
    margin: 0;
    padding: 0.25rem 0 0 0;
}

.panel.pricing .icon {
    margin-bottom: 0.5rem;
}

.panel.pricing table {
    margin-bottom: 0
}

.pricing .panel-body {
    padding: 0 0 0 0;
    text-align: center;
    border-top: 0;
}

.panel.pricing .prices {
    padding: 0;
    margin: 0;
    line-height: 1;
    background: none;
    font-weight: normal;
}

.price-value {
    font-size: 1.8rem
}

.price-currency {
    display: inline-block;
    font-size: 0.9rem;
    padding-right: 0.2rem;
}

.price-duration {
    font-size: 0.8rem;
}

.price-duration:before {
    content: "/";
    padding-right: 0.25rem;
}

.panel-desc {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0;
    line-height: 1;
}

.panel.pricing .table>thead>tr>th,
.panel.pricing .table>tbody>tr>th,
.panel.pricing .table>tfoot>tr>th,
.panel.pricing .table>thead>tr>td,
.panel.pricing .table>tbody>tr>td,
.panel.pricing .table>tfoot>tr>td {
    padding: 0.25rem 0;
    line-height: 1;
    vertical-align: top;
}

.pricing .table>tbody>tr>td {
    border: 0
}

.panel.pricing .table>tbody>tr:nth-child(2n) {
    background: none
}

.panel.pricing .panel-footer a {
    margin: 0
}

.panel.pricing .panel-title,
.pricing .panel-body {
    border: 0;
    background: none;
}

.pricing .panel-footer {
    display: flex;
    /* Enables flexbox */
    justify-content: center;
    /* Horizontally centers the content */
    align-items: center;
    /* Vertically centers the content */
    padding-top: 2.25rem;
    padding-bottom: 0;
    background: none;
    border: 0;
}

.pricing-switcher-wrapper {
    width: 10rem;
    height: 1.8rem;
    margin: 0 auto;
    clear: both;
    text-align: center;
    position: relative;
    background-color: rgba(var(--rgb-black), 0.05);
    border-radius: 1.5rem;
    border: 0.2rem solid transparent;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.pricing-switchers {
    width: 100%;
}

.pricing-switcher {
    cursor: pointer;
    width: 50%;
    float: left;
    height: 1.8rem;
    line-height: 1.8rem;
    position: relative;
    z-index: 888;

    text-transform: uppercase;
    font-size: 0.65rem;
    letter-spacing: 0.025rem;
    font-weight: 500;
}

.switcher-button {
    height: 1.8rem;
    width: 50%;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 555;
    margin: 0;
    border: none;

    border-radius: 1.5rem;
}

.pricing-switcher-active {
    color: var(--color-white);
}

.pricing-switcher:nth-child(1).pricing-switcher-active~.switcher-button {
    left: 0;
}

.pricing-switcher:nth-child(2).pricing-switcher-active~.switcher-button {
    left: 50%;
}

.prices {
    position: relative;
    height: 3rem;
}

.prices .price {
    position: absolute;
    left: 0;
    right: 0;
}

.prices .price-hidden {
    display: none;
}

@keyframes priceFade {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(80%);
        transform: translateY(80%);
    }
}

@-webkit-keyframes priceFade {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(80%);
        transform: translateY(80%);
    }
}

@keyframes priceShow {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-80%);
        transform: translateY(-80%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes priceShow {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-80%);
        transform: translateY(-80%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}


/*-----------------------------------------------------------------------------------*/
/*  48. COLUMNS & GUTTERS
/*-----------------------------------------------------------------------------------*/
.column-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

.column-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
}

.column-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
}

.gutter-20>[class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

.gutter-40>[class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
}

.gutter-50>[class*="col-"] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 20px;
}

.gutter-60>[class*="col-"] {
    padding-right: 30px;
    padding-left: 30px;
}

.gutter-70>[class*="col-"] {
    padding-right: 35px;
    padding-left: 35px;
}

.gutter-80>[class*="col-"] {
    padding-right: 40px;
    padding-left: 40px;
}


/*-----------------------------------------------------------------------------------*/
/*  62. INVERSE TEXT
/*-----------------------------------------------------------------------------------*/
.inverse-text p,
.inverse-text li,
.inverse-text span,
.inverse-text h1:not([class*="color-"]),
.inverse-text h2:not([class*="color-"]),
.inverse-text h3:not([class*="color-"]),
.inverse-text h4:not([class*="color-"]),
.inverse-text h5:not([class*="color-"]),
.inverse-text h6:not([class*="color-"]),
.inverse-text.transparent:not(.fixed) .navbar-nav .nav-link,
.inverse-text.transparent:not(.fixed) .navbar-other .nav-item a,
.inverse-text.dark-wrapper .navbar-nav .nav-link,
.inverse-text.dark-wrapper .navbar-other .nav-item a,
.inverse-text.dark-wrapper .navbar-other .nav-item button,
.inverse-text.transparent:not(.fixed) .navbar-other .nav-item button,
.inverse-text.navbar-fancy-dark .navbar-nav .nav-link,
.inverse-text.navbar-fancy-dark .navbar-other .nav-item a,
.inverse-text.navbar-fancy-dark .navbar-other .nav-item button,
.inverse-text .blockquote-footer,
.inverse-text .color-dark,
.inverse-text .meta,
.inverse-text .meta a,
.inverse-text .widget a:hover,
.inverse-text .accordion-wrapper.simple .card-header button.collapsed,
.inverse-text label:not(.custom-control-label),
.inverse-text .pricing-switcher,
.inverse-text address,
.inverse-text .nav-tabs .nav-link {
    color: var(--color-white);
}

.inverse-text.dark-wrapper .navbar-other .nav-item button.hamburger:before,
.inverse-text.dark-wrapper .navbar-other .nav-item button.hamburger span,
.inverse-text.dark-wrapper .navbar-other .nav-item button.hamburger:after,
.inverse-text.navbar-fancy-dark .navbar-other .nav-item button.hamburger:before,
.inverse-text.navbar-fancy-dark .navbar-other .nav-item button.hamburger span,
.inverse-text.navbar-fancy-dark .navbar-other .nav-item button.hamburger:after {
    background: var(--color-white);
}

.offcanvas-info.inverse-text,
.offcanvas-info.inverse-text p,
.offcanvas-info.inverse-text li,
.offcanvas-info.inverse-text span,
.offcanvas-info.inverse-text a,
.offcanvas-info.inverse-text address,
footer.inverse-text address {
    color: #cacaca;
}

.inverse-text .meta a:hover,
.inverse-text .accordion-wrapper.simple .card-header button:hover {
    color: var(--color-default);
}

.inverse-text .accordion-wrapper:not(.simple) .card-body,
.inverse-text .bg-white p,
.inverse-text .bg-white li,
.inverse-text .bg-white span,
.inverse-text .panel.bg-white,
.inverse-text .bg-transparent-white p,
.inverse-text .bg-transparent-white li,
.inverse-text .bg-transparent-white span,
.inverse-text .panel.bg-transparent-white {
    color: #606060;
}

.inverse-text .bg-white .nav-tabs .nav-link,
.inverse-text .bg-transparent-white .nav-tabs .nav-link,
.inverse-text .nav-tabs.nav-tabs-bg .nav-item.show .nav-link span,
.inverse-text .nav-tabs.nav-tabs-bg .nav-item .nav-link:hover span,
.inverse-text .nav-tabs.nav-tabs-bg .nav-item .nav-link.active span {
    color: #404040;
}

.inverse-text .bg-white .nav-tabs .nav-item.show .nav-link,
.inverse-text .bg-white .nav-tabs .nav-item .nav-link:hover,
.inverse-text .bg-white .nav-tabs .nav-item .nav-link.active,
.inverse-text .bg-transparent-white .nav-tabs .nav-item.show .nav-link,
.inverse-text .bg-transparent-white .nav-tabs .nav-item .nav-link:hover,
.inverse-text .bg-transparent-white .nav-tabs .nav-item .nav-link.active {
    color: var(--color-default);
}

.inverse-text .bg-white .meta,
.inverse-text .bg-white .meta a,
.inverse-text .bg-white .blockquote-footer,
.inverse-text .bg-transparent-white .meta,
.inverse-text .bg-transparent-white .meta a,
.inverse-text .bg-transparent-white .blockquote-footer {
    color: #999 !important;
}

.inverse-text .bg-white .color-dark,
.inverse-text .bg-transparent-white .color-dark {
    color: var(--color-dark)
}

.inverse-text blockquote.icon:before {
    color: rgba(var(--rgb-white), 0.4);
}

.inverse-text .bg-white blockquote.icon:before,
.inverse-text .bg-transparent-white blockquote.icon:before {
    color: rgba(var(--rgb-default), 0.3);
}

.inverse-text .divider-icon i {
    color: rgba(255, 255, 255, 0.1);
}

.inverse-text .divider-icon:before,
.inverse-text .divider-icon:after {
    border-top: 1px solid rgba(var(--rgb-white), 0.1);
}

.inverse-text .bg-white h1:not([class*="color-"]),
.inverse-text .bg-white h2:not([class*="color-"]),
.inverse-text .bg-white h3:not([class*="color-"]),
.inverse-text .bg-white h4:not([class*="color-"]),
.inverse-text .bg-white h5:not([class*="color-"]),
.inverse-text .bg-white h6:not([class*="color-"]),
.inverse-text .bg-transparent-white h1:not([class*="color-"]),
.inverse-text .bg-transparent-white h2:not([class*="color-"]),
.inverse-text .bg-transparent-white h3:not([class*="color-"]),
.inverse-text .bg-transparent-white h4:not([class*="color-"]),
.inverse-text .bg-transparent-white h5:not([class*="color-"]),
.inverse-text .bg-transparent-white h6:not([class*="color-"]) {
    color: #404040;
}

.page-title-wrapper.inverse-text .meta,
.page-title-wrapper.inverse-text .meta a,
.page-title-wrapper.inverse-text .breadcrumbs li,
.page-title-wrapper.inverse-text .breadcrumbs li a,
.page-title-wrapper.inverse-text .breadcrumbs li+li:before {
    color: #DDD;
}

.page-title-wrapper.inverse-text .meta a:hover,
.page-title-wrapper .breadcrumbs li a:hover {
    color: var(--color-white);
}

.inverse-text .meta span+span:before,
.caption-overlay .meta span+span:before {
    background: rgba(var(--rgb-white), 0.5);
}

.inverse-text .owl-carousel .owl-dots .owl-dot span {
    background: rgba(var(--rgb-white), 0.6);
}

.inverse-text select,
.inverse-text textarea,
.inverse-text textarea.form-control,
.inverse-text input[type="text"]:not(.qty),
.inverse-text input[type="password"],
.inverse-text input[type="datetime"],
.inverse-text input[type="datetime-local"],
.inverse-text input[type="date"],
.inverse-text input[type="month"],
.inverse-text input[type="time"],
.inverse-text input[type="week"],
.inverse-text input[type="number"],
.inverse-text input[type="email"],
.inverse-text input[type="url"],
.inverse-text input[type="search"],
.inverse-text input[type="tel"],
.inverse-text input[type="color"],
.inverse-text .uneditable-input {
    background: rgba(var(--rgb-white), 0.07);
    color: var(--color-white);
}

.inverse-text textarea:focus,
.inverse-text textarea.form-control:focus,
.inverse-text input[type="text"]:focus,
.inverse-text input[type="password"]:focus,
.inverse-text input[type="datetime"]:focus,
.inverse-text input[type="datetime-local"]:focus,
.inverse-text input[type="date"]:focus,
.inverse-text input[type="month"]:focus,
.inverse-text input[type="time"]:focus,
.inverse-text input[type="week"]:focus,
.inverse-text input[type="number"]:focus,
.inverse-text input[type="email"]:focus,
.inverse-text input[type="url"]:focus,
.inverse-text input[type="search"]:focus,
.inverse-text input[type="tel"]:focus,
.inverse-text input[type="color"]:focus,
.inverse-text .uneditable-input:focus {
    background: rgba(var(--rgb-white), 0.09);
}

.inverse-text .custom-select {
    background: rgba(var(--rgb-black), 0.35);
}

.inverse-text .custom-select:focus {
    background: rgba(var(--rgb-black), 0.5);
}

.inverse-text .form-control::-webkit-input-placeholder {
    color: var(--color-white);
}

.inverse-text .form-control:-moz-placeholder {
    color: var(--color-white);
}

.inverse-text .form-control::-moz-placeholder {
    color: var(--color-white);
}

.inverse-text .form-control:-ms-input-placeholder {
    color: var(--color-white);
}

.search-form.inverse-text:before {
    color: var(--color-white);
}

.inverse-text hr {
    border-color: rgba(var(--rgb-white), 0.15);
}

.inverse-text .custom-control-label::before {
    background-color: rgba(var(--rgb-black), 0.35);
}

.inverse-text .custom-control-input:disabled~.custom-control-label::before {
    background-color: rgba(var(--rgb-black), 0.2);
}

.inverse-text .pricing-switcher-wrapper {
    background-color: rgba(var(--rgb-white), 0.05);
}

.inverse-text .dial {
    border: 2px dashed rgba(var(--rgb-white), 0.07);
}

.inverse-text .progressbar svg path:first-child {
    stroke: rgba(var(--rgb-white), 0.1);
}

.inverse-text .progressbar-text {
    color: var(--color-white) !important;
}

.inverse-text .btn:not(.btn-white),
.inverse-text .btn:not(.btn-white):hover,
.inverse-text .btn:not(.btn-white):focus,
.inverse-text .btn:not(.btn-white):active,
.inverse-text .btn:not(.btn-white).active {
    color: var(--color-white) !important;
}

.inverse-text .divider-heading {
    opacity: 0.4;
}

/*-----------------------------------------------------------------------------------*/
/*  63. BOX LAYOUT
/*-----------------------------------------------------------------------------------*/
@media (min-width: 1260px) {
    .box-layout {
        position: relative;
        background: #eef0f2;
    }

    .box-layout .content-wrapper,
    .box-layout footer:not(.blockquote-footer) {
        width: 1260px !important;
        margin: 0 auto;
        position: relative;
    }
}

/*-----------------------------------------------------------------------------------*/
/*  64. RESPONSIVE
/*-----------------------------------------------------------------------------------*/

@media (min-width: 769px) and (max-width: 1199px) {
    .hide-on-medium {
        display: none;
    }
}

@media (min-width: 1280px) {
    .split-layout .content-wrapper {
        position: relative;
        width: 100%;
    }

    .split-layout .content-left {
        width: 50%;
        height: 100vh;
        position: fixed;
        max-height: 100vh;
        overflow: hidden;
    }

    .split-layout .content-right {
        margin-left: 50%;
        height: 100vh;
    }

    .split-layout .inner {
        padding: 3rem;
    }
}

@media (max-width: 1278.98px) {
    .split-layout .content-left {
        height: 500px;
        position: relative !important;
    }

    .split-layout .inner {
        padding: 5rem;
    }
}

@media (min-width: 1200px) {
    .rev_slider_wrapper {
        min-height: 500px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (max-width: 1024px) {}

@media (min-width: 992px) {
    .navbar-nav .nav-link {
        padding-top: 1.7rem;
        padding-bottom: 1.7rem;
    }

    .navbar.transparent .navbar-nav .nav-link {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .navbar.fixed .navbar-nav .nav-link,
    .navbar.navbar-fancy .navbar-nav .nav-link {
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
    }

    .navbar-nav .nav-link:hover,
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.highlighted,
    .dropdown-item:hover,
    .dropdown-item:focus,
    .mega-menu-content a:hover,
    .mega-menu-content a:focus {
        background: none;
    }

    .navbar-nav .nav-link:hover,
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.highlighted,
    .navbar-other .nav-item a:not(.btn):hover,
    .navbar-other .nav-item button:hover,
    .dropdown-item:hover,
    .dropdown-item:focus,
    .mega-menu-content a:hover,
    .mega-menu-content a:focus {
        color: var(--color-default);
    }

    .navbar-hover-opacity:not(.inverse-text) .navbar-nav .nav-link:hover,
    .navbar-hover-opacity:not(.inverse-text) .navbar-nav .nav-link.active,
    .navbar-hover-opacity:not(.inverse-text) .navbar-nav .nav-link.highlighted,
    .navbar-hover-opacity:not(.inverse-text) .navbar-other .nav-item a:not(.btn):hover,
    .navbar-hover-opacity:not(.inverse-text) .navbar-other .nav-item button:hover,
    .navbar-hover-opacity.transparent.fixed .navbar-nav .nav-link:hover,
    .navbar-hover-opacity.transparent.fixed .navbar-nav .nav-link.active,
    .navbar-hover-opacity.transparent.fixed .navbar-nav .nav-link.highlighted,
    .navbar-hover-opacity.transparent.fixed .navbar-other .nav-item a:not(.btn):hover,
    .navbar-hover-opacity.transparent.fixed .navbar-other .nav-item button:hover {
        color: rgba(48, 48, 48, 0.7);
    }

    .inverse-text.navbar-hover-opacity:not(.fixed) .navbar-nav .nav-link:hover,
    .inverse-text.navbar-hover-opacity:not(.fixed) .navbar-nav .nav-link.active,
    .inverse-text.navbar-hover-opacity:not(.fixed) .navbar-nav .nav-link.highlighted,
    .inverse-text.navbar-hover-opacity:not(.fixed) .navbar-other .nav-item a:not(.btn):hover,
    .inverse-text.navbar-hover-opacity:not(.fixed) .navbar-other .nav-item button:hover,
    .inverse-text.navbar-hover-opacity.dark-wrapper.fixed .navbar-nav .nav-link:hover,
    .inverse-text.navbar-hover-opacity.dark-wrapper.fixed .navbar-nav .nav-link.active,
    .inverse-text.navbar-hover-opacity.dark-wrapper.fixed .navbar-nav .nav-link.highlighted,
    .inverse-text.navbar-hover-opacity.dark-wrapper.fixed .navbar-other .nav-item a:not(.btn):hover,
    .inverse-text.navbar-hover-opacity.dark-wrapper.fixed .navbar-other .nav-item button:hover {
        color: rgba(var(--rgb-white), 0.7);
    }

    .navbar-hover-opacity .dropdown-item:hover,
    .navbar-hover-opacity .dropdown-item:focus,
    .navbar-hover-opacity .mega-menu-content a:hover,
    .navbar-hover-opacity .mega-menu-content a:focus {
        color: rgba(var(--rgb-white), 0.75);
    }

    .navbar-other.inverse-text .nav-item a:not(.btn),
    .navbar-other.inverse-text .nav-item button {
        color: var(--color-white);
    }

    .navbar-other.inverse-text .nav-item a:not(.btn):hover,
    .navbar-other.inverse-text .nav-item button:hover {
        color: var(--color-white) !important;
        opacity: 0.8 !important;
    }

    .navbar.extended:not(.fixed) .navbar-collapse,
    .navbar.navbar-fancy:not(.fixed) .dropdown-menu {
        border-radius: 0.2rem;
    }

    .navbar .navbar-nav .nav-link {
        padding-right: 0.7rem;
        padding-left: 0.7rem
    }

    @media (max-width: 1200px) {
        .navbar .navbar-nav .nav-link {
            padding-right: 0.6rem;
            padding-left: 0.6rem;
        }
    }

    .dropdown-menu:not(.mega-menu):not(.image-dropdown) {
        padding: 0.6rem 0;
    }

    .search-dropdown .dropdown-menu {
        padding: 0 !important;
    }

    .navbar-other .nav-item a:not(.btn),
    .navbar-other .nav-item button {
        font-size: 1.1rem;
    }

    .dropdown-item,
    .mega-menu-content a {
        font-size: 0.75rem;
        font-weight: 400;
    }

    .navbar.wide .navbar-nav>li>ul.mega-menu,
    .navbar.wide .navbar-nav>.nav-item>.search-dropdown-menu {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .navbar.wide .navbar-nav>.nav-item>.search-dropdown-menu {
        width: calc(100% - 60px) !important
    }

    .navbar-nav>li>ul.mega-menu .mega-menu-content .row {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        overflow: hidden;
    }

    .mega-menu-content .row {
        padding-left: 0;
        padding-right: 0;
    }

    .mega-menu-content [class*="col-"] {
        padding-left: 1.5rem;
    }

    .navbar-nav>li>ul.mega-menu .mega-menu-content {
        padding: 1.5rem;
    }

    .navbar.extended .navbar-collapse {
        background: var(--color-white);
        -webkit-box-shadow: var(--shadow);
        box-shadow: var(--shadow);
        width: 100%;
    }

    .navbar.extended .logo-dark {
        display: none;
    }

    .navbar.navbar-fancy:not(.fixed) .container {
        background: var(--color-white);
        -webkit-box-shadow: var(--shadow);
        box-shadow: var(--shadow);
    }

    .navbar.navbar-fancy.navbar-fancy-dark:not(.fixed) .container {
        background: #242930;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .navbar.navbar-fancy.navbar-bg-opacity:not(.fixed) .container {
        background: rgba(var(--rgb-white), 0.9);
    }

    .navbar.navbar-fancy:not(.banner--clone) {
        margin-top: 2rem;
    }

    .navbar.navbar-fancy .container {
        padding: 0 30px;
        border-radius: 0.2rem;
        border-radius: 0.2rem;
    }

    .navbar.navbar-fancy:not(.banner--clone) .navbar-nav>.nav-item>.dropdown-menu,
    .navbar.extended:not(.banner--clone) .navbar-nav>.nav-item>.dropdown-menu {
        margin-top: 10px !important;
    }

    .navbar.extended .navbar-brand {
        position: absolute;
        top: 2rem;
        left: 0;
        width: 100%;
        pointer-events: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .navbar.extended .navbar-brand * {
        pointer-events: all;
    }

    .navbar.extended .topbar {
        padding: 1.75rem 0;
    }

    .navbar.extended.fixed .topbar {
        display: none !important;
    }

    .navbar.extended .navbar-nav .nav-link {
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
    }

    .table-cart>table>thead>tr>th,
    .table-cart>table>tbody>tr>th,
    .table-cart>table>tfoot>tr>th,
    .table-cart>table>thead>tr>td,
    .table-cart>table>tbody>tr>td,
    .table-cart>table>tfoot>tr>td {
        padding: 20px 0;
    }

    .navbar-nav.navbar-bullet>li>a {
        position: relative;
    }

    .navbar-nav.navbar-bullet>li>a:before {
        content: "";
        display: block;
        position: absolute;
        width: 3px;
        height: 3px;
        top: 50%;
        margin-top: -2px;
        left: -2px;
        background: rgba(var(--rgb-black), 0.25);
        border-radius: 50%;

    }

    .navbar-nav.navbar-bullet>li:first-child a:before {
        display: none
    }

    .navbar-nav.navbar-bullet .nav-link {
        padding-right: 1.7rem;
        padding-left: 1.7rem
    }

    .pr-lg-15 {
        padding-right: 15px !important;
    }

    .pl-lg-15 {
        padding-left: 15px !important;
    }

    .modal .image-block-wrapper,
    .modal .image-block-wrapper .box {
        min-height: 500px;
    }

    .boxed .rounded .image-block>.google-map,
    .boxed .rounded .image-block>.google-map iframe {
        border-top-left-radius: 0.2rem !important;
        border-bottom-left-radius: 0.2rem !important;
    }
}

@media (max-width: 991.98px) {

    .boxed .rounded .image-block>.google-map,
    .boxed .rounded .image-block>.google-map iframe {
        border-top-left-radius: 0.2rem !important;
        border-top-right-radius: 0.2rem !important;
    }

    .navbar-other {
        padding-left: 3px;
    }

    .search-dropdown .dropdown-menu,
    .cart-dropdown .dropdown-menu {
        position: absolute;
    }

    .navbar.extended:not(.fixed),
    .navbar.navbar-fancy:not(.fixed) {
        background: var(--color-white);
        -webkit-box-shadow: var(--shadow);
        box-shadow: var(--shadow);
    }

    .navbar.navbar-fancy.navbar-fancy-dark:not(.fixed) {
        background: #242930 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .navbar-nav .nav-link {
        color: var(--color-white);
    }

    .navbar-nav .nav-link,
    .dropdown-item,
    .mega-menu-content a {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
    }

    .navbar-other .nav-item a:not(.btn),
    .navbar-other .nav-item button {
        font-size: 1.3rem;
    }

    .navbar-nav,
    .nav-fill>.nav-item {
        text-align: left !important;
    }

    .navbar-nav .nav-link[href='#!'] {
        pointer-events: none;
    }

    .navbar-nav.sm-collapsible .nav-link[href='#!'] .sub-arrow {
        pointer-events: auto;
    }

    .dropdown-item {
        padding-left: 0;
        padding-right: 0;
    }

    .dropdown-item,
    .mega-menu-content a {
        font-size: 0.8rem;
        font-weight: 400;
    }

    .navbar .dropdown-menu:not(.search-dropdown-menu) {
        padding-left: 1rem
    }

    .navbar .search-dropdown .dropdown-menu .form-wrapper {
        position: relative;
    }

    .navbar.extended .search-dropdown-menu {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .navbar .navbar-brand {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .navbar.fixed .navbar-brand {
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
    }

    .navbar.extended .logo-dark {
        display: inline-block;
    }

    .navbar.extended .logo-light {
        display: none;
    }

    .offcanvas-nav {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 100%;
        left: -300px;
        width: 300px;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        padding-bottom: 0.5rem;
        overflow-y: auto;
        visibility: hidden;
        background: #0b2436;

        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: start;
        -moz-background-clip: padding;
        -webkit-background-clip: padding;
        background-clip: padding-box;
    }

    .offcanvas-nav.open {
        left: 0;
        visibility: visible;
    }

    .navbar.fixed .offcanvas-nav.open {
        display: none !important;
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .image-block-wrapper .image-block {
        position: relative;
        height: 450px;
    }

    .modal .image-block-wrapper .image-block {
        display: none;
    }

    .modal .image-block-wrapper,
    .modal .image-block-wrapper .box {
        min-height: inherit;
    }

    .nav-tabs.d-flex:not(.nav-pills) {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .pr-md-0 {
        padding-right: 0 !important;
    }

    .pl-md-0 {
        padding-left: 0 !important;
    }

    .pr-md-15 {
        padding-right: 15px !important;
    }

    .pl-md-15 {
        padding-left: 15px !important;
    }

    .gutter-md-30>[class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    [class*="table-responsive"]>thead>tr>th,
    [class*="table-responsive"]>tbody>tr>th,
    [class*="table-responsive"]>tfoot>tr>th,
    [class*="table-responsive"]>thead>tr>td,
    [class*="table-responsive"]>tbody>tr>td,
    [class*="table-responsive"]>tfoot>tr>td {
        white-space: nowrap
    }

    .sidebar {
        padding-left: 15px;
        margin-top: 3rem;
    }

    .sidebar.left-sidebar {
        padding-right: 15px !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    .mosaic .item,
    .mosaic .item.width2 {
        width: 50%;
        height: auto;
    }
}

@media (min-width: 768px) {
    .process-wrapper.arrow [class*="col-"]:before {
        font-family: 'Jam';
        content: "\e92c";
        display: block;
        position: absolute;
        top: 1.35rem;
        left: 0;
        color: rgba(var(--rgb-black), 0.15);
        font-size: 1.5rem;
    }

    .inverse-text .process-wrapper.arrow [class*="col-"]:before {
        color: rgba(var(--rgb-white), 0.15);
    }

    .process-wrapper.arrow [class*="col-"]:first-child:before {
        display: none;
    }

    .process-wrapper.line [class*="col-"] {
        position: relative;
    }

    .process-wrapper.line [class*="col-"]:before,
    .process-wrapper.line [class*="col-"]:after {
        width: calc(50% - 3rem);
        position: absolute;
        content: "";
        height: 1px;
        background: none;
        border-top: 2px dashed rgba(var(--rgb-black), 0.07);
        top: 2rem;
        z-index: 1;
        left: 0;
        margin-left: 3px;
    }

    .inverse-text .process-wrapper.line [class*="col-"]:before,
    .inverse-text .process-wrapper.line [class*="col-"]:after {
        border-top-color: rgba(var(--rgb-white), 0.07);
    }

    .process-wrapper.line [class*="col-"]:after {
        right: 0;
        left: auto;
        margin-right: 3px;
        margin-left: 0;
    }

    .process-wrapper.line [class*="col-"]:first-child:before,
    .process-wrapper.line [class*="col-"]:last-child:after {
        display: none;
    }

    .pricing-wrapper .popular .panel.pricing.box {
        padding-top: 4rem;
        padding-bottom: 4rem;
        margin-top: -1rem;
        margin-left: -0.2rem;
        margin-right: -0.2rem;
    }

    .nav-tabs.nav-pills .nav-link .icon {
        display: inline-block;
    }
}

@media (max-width: 767px) {
    .container {
        padding-right: 20px;
        padding-left: 20px;
    }

    .navbar .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .row>[class*="col-"] {
        margin-bottom: 30px
    }

    .row>[class*="col-"]:last-child,
    .row>[class*="col-"]:only-child,
    .mega-menu-content .row>[class*="col-"],
    form .row>[class*="col-"],
    .block-wrapper>[class*="col-"] {
        margin-bottom: 0;
    }

    .grid-view .post {
        margin-bottom: 3rem;
    }

    .pr-sm-0 {
        padding-right: 0 !important;
    }

    .pl-sm-0 {
        padding-left: 0 !important;
    }

    .pr-sm-15 {
        padding-right: 15px !important;
    }

    .pl-sm-15 {
        padding-left: 15px !important;
    }

    .inner {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .inner-top {
        padding-top: 4rem;
    }

    .inner-bottom {
        padding-bottom: 4rem;
    }

    footer .inner {
        padding-bottom: 3rem;
    }

    footer .inner-top {
        padding-top: 4rem;
    }

    footer .inner-bottom {
        padding-bottom: 4rem;
    }

    .page-title-wrapper .inner {
        padding-top: 3rem;
    }

    .page-title-wrapper.image-wrapper .inner {
        padding-bottom: 4rem;
    }

    .dial:not(.noResponsive) {
        border-radius: 0;
        border: none;
        width: 100%;
    }

    .dial:not(.noResponsive) li {
        margin-bottom: 1rem;
    }

    .dial-item-info:not(.noResponsive) {
        padding: 0.8rem 0.5rem;
        display: block !important;
        position: static;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }

    .dial-item:not(.noResponsive) {
        position: static;
        display: block;
        width: 75px;
        height: 75px;
        line-height: 75px !important;
        margin: auto;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }

    .dial-item-info {
        background: none;
    }

    .nav-tabs.nav-justified {
        display: block !important;
    }

    .nav-tabs.nav-justified .nav-item {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        margin-left: 0;
        margin-right: 0;
        display: block;
        margin-bottom: 0.75rem;
        width: 100%;
        text-align: center !important;
    }

    .nav-tabs.nav-justified .nav-item .nav-link * {
        display: inline !important;
    }

    .nav-tabs.nav-justified .nav-item .nav-link span {
        padding-left: 0.5rem;
    }

    .nav-tabs.nav-justified .nav-item .nav-link .icon-svg img {
        width: 2rem;
        height: 2rem;
    }

    .nav-tabs.nav-pills {
        display: block !important;
    }

    .nav-tabs.nav-pills .nav-item {
        display: block !important;
    }

    .nav-tabs.nav-pills .nav-item+.nav-item {
        margin-top: 1rem;
    }

    .nav-tabs.nav-pills .nav-link {
        display: inline-block !important;
    }

    .nav-tabs.nav-pills .nav-link .icon {
        display: inline-block !important;
    }

    .nav-tabs.nav-tabs-centered.nav-justified .nav-item {
        text-align: left !important;
    }

    .nav-tabs.d-flex:not(.nav-pills) {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    #comments .user {
        display: none
    }

    #comments .message-inner {
        margin-left: 0
    }

    #comments ul.children {
        padding: 0 0 0 25px
    }

    .mosaic .item,
    .mosaic .item.width2 {
        width: 100%;
        height: auto;
    }

    .overflow-top.bleed-sm-s {
        margin-top: -6rem;
    }

    .overflow-bottom {
        margin-bottom: -7rem;
    }

    .image-wrapper.bg-full {
        background-size: cover !important;
    }

    .image-block-wrapper [class*="container"] .box {
        padding: 4rem 3rem;
    }
}

@media (max-width: 575.98px) {

    .navbar .dropdown-menu.search-dropdown-menu,
    .navbar .dropdown-menu.search-dropdown-menu input {
        border-radius: 0 !important;
    }
}

@media (min-width:576px) {
    .modal-sm {
        max-width: 500px
    }
}

@media (min-width:576px) {
    .modal-sm {
        max-width: 500px
    }
}

@media (min-width:992px) {
    .modal-md {
        max-width: 800px
    }

    .modal-lg {
        max-width: 1000px
    }
}

.modal.fade.modal-bottom-center .modal-dialog {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

@media (min-width: 576px) {
    .modal.fade.modal-bottom-center .modal-dialog {
        margin: 1.75rem auto;
    }
}

@media (max-width: 767.98px) {
    .modal.fade.modal-bottom-center .modal-dialog {
        width: calc(100% - (0.5rem*2));
    }
}

.blog-posts .post-thumb {
    max-height: 245px;
    -o-object-fit: cover;
    object-fit: cover;
}


/*****************************
CUSTOM CSS ADDED BY NYCKEL
******************************/

.grid-view .post-thumb {
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
}

#results-container {
    margin: 0;
    padding: 0;
    list-style: none;
}

#results-container li {
    padding: 8px 15px 8px 20px;
}

#results-container li:first-child {
    padding-top: 0;
}

#results-container li:last-child {
    padding-bottom: 30px;
    border-bottom: 0;
}

#results-container li a {
    font-size: 16.5px;
    border-bottom: .5px solid #d5d5d5;
    color: #fff;

}

#results-container li a:hover {
    color: #ddd;
}

.banner--stick .search-dropdown {
    opacity: 0;
    visibility: hidden;
    width: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.case-studies {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* Align to the start to manage spacing manually */
    margin-left: -15px;
    /* Negative margin to counteract the padding on each box */
    margin-right: -15px;
    margin-bottom: -5%;
}

.case-study-box {
    width: calc(33.333% - 30px);
    /* Width to fit 3 boxes in a row minus the total margin */
    margin: 15px;
    /* Margin around the boxes */
    padding: 20px;
    /* Padding inside the boxes */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Shadow effect */
    background-color: #FFF;
    /* Background color for the box */
    border-radius: 10px;
    /* Rounded corners for the box */
    text-decoration: none;
    /* No underline on the link */
    color: inherit;
    /* Inherit the font color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth transitions for hover effects */
    display: block;
    /* Block display for the link */
    border-radius: 10px;
}

/* Hover effect */
.case-study-box:hover {
    transform: scale(1.03);
    /* Scale up the box slightly */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    /* Deeper shadow for hover effect */
}

/* Logo Image */
.case-study-box .logo img {
    max-width: calc(100% + 40px);
    height: auto;
    margin-bottom: 20px;
    margin-left: calc(-20px);
    margin-right: calc(-20px);
    margin-top: calc(-20px);
    border-radius: 10px 10px 0px 0px
}

/* Quote Style */
.case-study-box .quote {
    margin-bottom: 20px;
    height: 110px;
    /* Space below the quote */
    color: #0b2436;
}

/* Author Image and Name */
.case-study-box .author {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    /* Space below the author section */
    color: #0b2436;
    font-size: 0.7rem;
}

.case-study-box .author img {
    border-radius: 50%;
    /* Make the author image circular */
    margin-right: 10px;
    /* Space to the right of the author image */
    width: 50px;
    /* Width of the author image */
    height: 50px;
    /* Height of the author image */
}

/* Link to Case Study */
.case-study-box .case-study-link {
    color: #377db7;
    /* Color for the link text */
    font-weight: bold;
    /* Make the link text bold */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 992px) {
    .case-study-box {
        width: calc(50% - 30px);
        /* Adjust to have two boxes per row */
    }
}

@media (max-width: 768px) {
    .case-study-box {
        width: calc(100% - 30px);
        /* Adjust to have one box per row */
    }
}

.rounded-shadow-image img {
    border-radius: 10px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Light shadow */
    width: auto;
    /* Maintain the original width */
    height: auto;
    /* Maintain the original height */
}

.logo-matrix {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* This will space out the logos evenly */
    max-width: 100%;
}

.logo-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    /* Spacing between the rows */
    width: 100%;
}

.logo-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48%;
    /* Slightly less than half to account for the space-between */
}

/* Existing CSS */
.logo-cell img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {

    .logo-cell img,
    .row.clients img {
        max-width: 40% !important;
        height: auto;
    }
}

.logo-matrix .logo-cell img {
    max-width: 50%;
    /* Reducing the size to 70% of the original */
    height: auto;
    /* Maintain the aspect ratio */
}

.leadership-team {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    /* Controls space between items */
}

.team-member {
    flex: 1 1 calc(25% - 10px);
    /* Adjust width based on gap */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    background-color: #FFF;
}

.security-item {
    flex: 0 0 40% !important;
    /* 4 per row, minus some space for margins */
}

.team-photo {
    width: 100px;
    /* Example size, adjust as needed */
    height: 100px;
    /* Maintain aspect ratio */
    border-radius: 50%;
    /* Circular photo */
    margin-bottom: 10px;
    /* Space below the photo */
}

.security-photo {
    width: 65px;
    /* Example size, adjust as needed */
    height: 65px;
    /* Maintain aspect ratio */

    margin-bottom: 10px;
    /* Space below the photo */
}

.team-name {
    margin: 0;
    font-size: 1.15rem;
    /* Example size, adjust as needed */
}

.team-title {
    margin: 0;
    font-size: 0.8rem;
    /* Example size, adjust as needed */
    color: #666;
    /* Grey text for the title */
}

.security-title {
    margin: 0;
    font-size: 0.8rem;
    /* Example size, adjust as needed */
    color: #666;
    /* Grey text for the title */
    padding-top: 5px;
}


/* Responsive adjustments if necessary */
@media (max-width: 1200px) {
    .team-member {
        flex: 0 0 48%;
        /* Adjust to have two items per row on medium screens */
    }
}

@media (max-width: 768px) {
    .team-member {
        flex: 0 0 100%;
        /* Adjust to have one item per row on small screens */
    }
}

.function-text {
    font-weight: 300;
    color: #FFFFFF;
    font-size: 1.1rem;
    line-height: 1.5;
}

.styled-as-h1 {
    font-size: 0.6em;
    font-weight: bold;
    /* Assuming h1 has bold weight */
    line-height: 1.7rem;
    color: #0b2436;
    /* Add other h1 styles here if needed */
}

.intro {
    font-size: 0.3em;
    font-weight: light;
    line-height: 1rem;
    color: #808080;
}

.spacemobile {
    height: 30px;
    display: none;
    /* Hide by default */
}

.spacemobile-s {
    height: 15px;
    display: none;
    /* Hide by default */
}

@media (max-width: 768px) {

    /* Adjust the max-width as needed for your mobile breakpoint */
    .spacemobile {
        display: block;
        /* Display on mobile */
    }
}

@media (max-width: 768px) {

    /* Adjust the max-width as needed for your mobile breakpoint */
    .spacemobile-s {
        display: block;
        /* Display on mobile */
    }
}

.posts {
    padding-top: 90px;
    padding-bottom: 1rem;
}


@media (max-width: 594.98px) {
    .posts {
        padding-top: 70px;
    }
}

.posts .heading {
    font-size: 2em;
    font-weight: 400;
    line-height: 1.6;
    margin-top: 2rem;
    margin-left: 0.5rem;
}

.posts .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.summary-card {
    box-shadow: 0px 0px 18px rgba(44, 68, 94, 0.1);
    height: 100%;
    transition: box-shadow 100ms;
    border: unset;
    border-radius: 13px;
}

.summary-card:hover {
    transform: scale(1.03);
    /* Scale up the box slightly */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    /* Deeper shadow for hover effect */
}

.summary-card h1 {
    font-family: "Arial", sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
    margin-bottom: 1.0rem;
    color: #0b2436;
}

.summary-img {
    height: 220px;
    object-fit: cover;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 15px;
}

.summary-cover {
    border-radius: 20px !important;
    max-width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.summary-card .entry {
    font-weight: 300;
    color: #0b2436;
    font-size: .80rem;
    line-height: 1.5;
}

.summary-footer {
    background-color: unset;
    border-top: unset;
}

.summary-footer .date {
    margin: auto 0;
    font-size: .75rem;
    font-weight: 300;
    line-height: 2.0;
    color: #0b2436;
    font-weight: 400;
}

.summary-footer .author-area {
    flex-direction: row;
}

.summary-footer .author-name {
    /* margin: 3px 10px 0 3px; */
    font-size: .95rem;
    font-weight: 300;
}

.summary-footer .author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary-footer .social-icon {
    font-size: 18px;
}

.summary-footer .entry {
    margin: 0 auto;
    padding: 2rem;
    max-width: 940px;
}

.hero .read-more,
.summary-footer .read-more {
    margin: auto 0;
    color: #0b2436;
    font-size: .9rem;
    font-weight: 500;

}

.hero .summary-card {
    height: unset;
}

@media (max-width: 594.98px) {
    .summary-footer .entry {
        padding: 2rem 1rem;
    }
}

.highlight table td {
    padding: 5px;
}

.highlight table pre {
    margin: 0;
}

.highlight,
.highlight .w {
    color: #586e75;
}

.highlight .err {
    color: #002b36;
    background-color: #dc322f;
}

.highlight .c,
.highlight .cd,
.highlight .cm,
.highlight .c1,
.highlight .cs {
    color: #657b83;
}

.highlight .cp {
    color: #b58900;
}

.highlight .nt {
    color: #b58900;
}

.highlight .o,
.highlight .ow {
    color: #93a1a1;
}

.highlight .p,
.highlight .pi {
    color: #93a1a1;
}

.highlight .gi {
    color: #859900;
}

.highlight .gd {
    color: #dc322f;
}

.highlight .gh {
    color: #268bd2;
    background-color: #002b36;
    font-weight: bold;
}

.highlight .k,
.highlight .kn,
.highlight .kp,
.highlight .kr,
.highlight .kv {
    color: #6c71c4;
}

.highlight .kc {
    color: #cb4b16;
}

.highlight .kt {
    color: #cb4b16;
}

.highlight .kd {
    color: #cb4b16;
}

.highlight .s,
.highlight .sb,
.highlight .sc,
.highlight .sd,
.highlight .s2,
.highlight .sh,
.highlight .sx,
.highlight .s1 {
    color: #859900;
}

.highlight .sr {
    color: #2aa198;
}

.highlight .si {
    color: #d33682;
}

.highlight .se {
    color: #d33682;
}

.highlight .nn {
    color: #b58900;
}

.highlight .nc {
    color: #b58900;
}

.highlight .no {
    color: #b58900;
}

.highlight .na {
    color: #268bd2;
}

.highlight .m,
.highlight .mf,
.highlight .mh,
.highlight .mi,
.highlight .il,
.highlight .mo,
.highlight .mb,
.highlight .mx {
    color: #859900;
}

.highlight .ss {
    color: #859900;
}

body {
    background-color: #fff;
}

/* Prevent bootstrap from messing with header and footer */
#header img,
#site-footer {
    vertical-align: unset;
}

#site-footer .col-3 {
    flex: unset;
    max-width: unset;
}

#site-footer ul {
    margin-top: 1em;
    margin-bottom: 1em;
}

/* Prevent marketing, server and client css from messing with blog */
h2,
h3,
h4 {
    padding-bottom: 0;
}

.entry h2 {
    margin-bottom: 1rem;
}

.entry h3 {
    margin-bottom: 0.7rem;
}

.entry h4 {
    font-weight: 300;
    margin-bottom: 0.5rem;
}

.entry p {
    font-size: 18px;
    /* Replace 16px with your desired font size */
}

.entry ul,
.entry ol,
.entry li {
    font-size: 18px;
    /* Match paragraph font size */
}

/* Actual site css */
.logo {
    display: flex;
    align-items: center;
}

.logo a {
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 0.1rem;
    font-family: "Arial", sans-serif;
    color: #16507b;
}

#header {
    background-color: #fff;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.06);
    border-bottom: 1px solid #e6f2fb;
}

#header-signup {
    font-weight: 600;
}

#menu-toggle {
    outline: none;
}

.newsletter-cta {
    border-radius: 0.625rem;
    background: linear-gradient(90deg, rgba(0, 127, 188, 0.50) 0%, rgba(0, 37, 55, 0.50) 100%, rgba(5, 173, 255, 0.50) 100%), #1C77BA;
    padding: 1.5rem;
    margin: 3rem;
}

.newsletter-cta h2,
.newsletter-cta p,
.newsletter-cta button {
    color: white;
    font-family: "Jost";
}

.newsletter-cta h2 {
    margin-top: 0 !important;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.newsletter-cta p {
    color: #dfdfdf;
}

.newsletter-cta button {
    display: flex;
    margin: auto;
    border: 2px solid #fff;
    font-family: "Jost", sans-serif;
    font-size: .95rem;
    line-height: 1.6;
    font-weight: 600;
    background-color: #F2A50D;
    border-radius: 0.6rem;
    padding: 0.8rem 1.6rem;
}

.newsletter-cta button:hover {
    background: var(--nyckel-color-cta);
}

.readingpane {
    width: 100%;
    font-family: Arial, sans-serif !important;
}

div.highlighter-rouge {
    border: 1px solid lightgrey;
    background-color: #f5f5f5;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 1em 1em 0em 1em;
    margin-top: 1em;
    margin-bottom: 1em;
}

code.highlighter-rouge {
    color: #b58900;
    padding: 0em 0em 0em 0em;
    margin-top: 0em;
    margin-bottom: 0em;
}

.post {
    padding-bottom: 1rem;
}

@media (max-width: 594.98px) {
    .post {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.post .comments {
    margin-top: 10px;
}

.post figure {
    display: block;
    margin: auto;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
}

.post figcaption {
    font-family: "Arial", sans-serif;
    font-size: 1em;
    color: #94979B;
    text-align: center;
}

.post table {
    font-family: "Arial", sans-serif;
    border-collapse: separate;
    font-size: 1em;
    width: 100%;
    border: 1px solid lightgrey;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 0;
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: center;
    border-spacing: 0;
}

.post .table-wrapper {
    display: block;
    overflow-x: auto;
    margin-top: 1em;
    margin-bottom: 1em;
}

.post .table-wrapper table {
    margin-top: 0;
    margin-bottom: 0;
}

.post th {
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    padding: 0.5em;
    font-weight: 500;
    background-color: #f5f5f5;
}

.post td {
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    padding: 0.5em;
    background-color: #fcfcfc;
}

.post th:last-child {
    border-right: none;
    --webkit-border-radius: 0 5px 0 0;
    border-radius: 0 6px 0 0;
}

.post td:last-child {
    border-right: none;
}

.post th:first-child {
    --webkit-border-radius: 5px 0 0 0;
    border-radius: 5px 0 0 0;
}

.post tr:last-child td {
    border-bottom: none;
}

.post tr:last-child td:first-child {
    --webkit-border-radius: 5px 0 0 0;
    border-radius: 0 0 0 5px;
}

.post tr:last-child td:last-child {
    --webkit-border-radius: 5px 0 0 0;
    border-radius: 0 0 5px 0;
}

.entry img {
    width: 100%;
}

article {
    margin-top: 85px;
    background-color: white;
}

@media (max-width: 594.98px) {
    article {
        margin-top: 60px;
    }
}

.hero .summary {
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
    color: #0b2436;
    font-size: .80rem;
}

.hero .back-link-container {
    height: 4rem;
    position: relative;
}

.hero .back-link {
    font-weight: 300;
    font-size: .85rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 768px) {
    .hero-contents {
        flex-direction: column;
    }

    .hero .back-link-container {
        height: 3rem;
    }
}

.hero .title-area {
    width: 50%;
}

.hero .title-area2 {
    padding-left: 5%;
    padding-right: 5%;
}

@media (max-width: 768px) {
    .hero .title-area {
        width: 100%;
        margin-right: 0;
    }
}

.hero .image-area {
    width: 90%;
    flex: 1;
    margin-left: -20px;
    padding-right: 2%;
    padding-left: 2%;
}

.hero .hero-contents {
    display: flex;
    align-items: center;
    /* Align items vertically in the center */
    justify-content: space-between;
    /* Optional: to space out the title and image */
}

@media (max-width: 768px) {
    .hero .image-area {
        max-width: 100%;
        width: 100%;
        margin: 0;
        margin-top: 2rem;
    }

    .hero .image-area {
        width: 100%;
        /* Adjust for smaller screens */
    }

    .hero .hero-contents {
        flex-direction: column;
        /* Stack items vertically on smaller screens */
    }
}

.hero .image-area img {
    height: 300px;
    width: 600px;
    object-fit: cover;
    /* Keeps the aspect ratio */
    border-radius: 20px;
    margin-left: -5%;
}

/* Safari only */
_::-webkit-full-page-media,
_:future,
:root .safari_only_unset_height {
    height: unset !important;
}

.hero h1 {
    font-family: "Arial", sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1.15;
    margin: 1rem 0 1rem 0;
    color: #0b2436;
}

@media (max-width: 594.98px) {
    .hero h1 {
        margin: 0 0 1rem 0;
    }
}

.author-icon {
    width: 32px;
    margin-right: 0.3rem;
    border-radius: 50%;
    vertical-align: middle;
}

.author {
    font-family: "Arial", sans-serif;
    font-weight: 500;
    font-size: .8rem;
    line-height: 1.8;
    letter-spacing: 0.05em;
}

.hero .date {
    margin: auto 0;
    font-size: .85rem;
    font-weight: 400;
}

.hero .author-area {
    /* display: flex; */
    flex-direction: row;
}

.hero .author-name {
    margin: 3px 10px 0 3px;
    font-size: .85rem;
    font-weight: 400;
    color: #0b2436;

}

.hero .author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;
}

.post .back-link,
.post h1,
.post .summary,
.post .date,
.post .author-name,
.post .social-icon {
    color: white;
}

.post .entry {
    margin: 0 auto;
    padding: 2rem 2rem 0;
    max-width: 940px;
}

.post .hero {
    background-color: #004566;
    padding-bottom: 4rem;
}

@media (max-width: 768px) {
    .post .hero {
        padding-bottom: 2rem;
    }
}

@media (max-width: 594.98px) {
    .post .entry {
        padding: 2rem 1rem;
    }
}

.entry h2 {
    margin-top: 1em;
    font-family: "Arial", sans-serif;
    font-weight: 300;
    font-size: 2rem;
    line-height: 2.3rem;
}

.entry h3 {
    margin-top: 1em;
    font-size: 1.5em;
    font-family: "Arial", sans-serif;
    font-weight: 300;
}

.entry h4 {
    margin-top: 1em;
    font-size: 1.2em;
    font-family: "Arial", sans-serif;
    font-weight: 300;
}

.youtube {
    text-align: center;
    margin-bottom: 2em;
    margin-top: 2em;
}

.youtube iframe {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* Example shadow */
}

.comment {
    border-color: #004566;
    border-style: solid;
    border-width: thin;
    font-style: italic;
    padding: 1rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    border-radius: 10px;
}

.comment-div {
    border-color: #004566;
    border-style: solid;
    border-width: thin;
    font-style: italic;
    padding: 1rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    border-radius: 10px;
    padding-bottom: 0px;
}

.highlights {
    background-color: #004566;
    color: #fff;
    padding: 2rem;
    border-radius: 10px;
}

.highlights dt {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
}

.highlights dd {
    margin-left: 2.4rem;
    display: list-item;
    font-size: 18px;
    font-weight: 300;
}

.customer-quote-block {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.customer-quote {
    font-style: italic;
    font-family: "Arial", sans-serif;
    font-size: 0.85rem;
    line-height: 1.1;
    text-align: center;
    color: #0b2436;
}

.customer-quote-signature {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.customer-quote-name {
    font-size: 1.1rem;
    line-height: 1.1;
    color: #000;
    padding-bottom: 5px;
    font-family: "Arial", sans-serif;
    font-weight: 400;
}

.customer-quote-title {
    font-size: .85rem;
    line-height: 1.1;
    color: #8a8a8a;
    font-family: "Arial", sans-serif;
    font-weight: 400;
}

@media(min-width: 600px) {
    .customer-quote {
        border-right: 1px solid #BADCEC;
        text-align: right;
    }

    .customer-quote-signature {
        justify-content: left;
    }
}

.case-study-mugshot {
    border-radius: 10px;
    /* Added border-radius */
    padding-left: 3%;
}

picture {
    display: inline-block;
    padding-right: 20px;
    /* Added padding */
}

.customer-quote-name {
    font-size: 0.8rem;
    /* Updated font size */
    line-height: 1;
    color: #0b2436;
    /* Updated color */
    padding-bottom: 5px;
    margin-left: -20px;
    font-family: "Arial", sans-serif;
    font-weight: 400;
}

.customer-quote-title {
    font-size: 0.7rem;
    /* Updated font size */
    line-height: 1;
    margin-left: -20px;
    color: #0b2436;
    /* Updated color */
    font-family: "Arial", sans-serif;
    font-weight: 400;
}

/* Add this to your CSS file */
.img.boxed {
    display: block;
    max-width: 100%;
    /* Make sure the image doesn't overflow its container */
    height: auto;
    /* Maintain aspect ratio */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.boxed {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 3px;
    max-width: 80%
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .boxed {
        min-width: 120%;
        /* Adjust the width as needed for mobile devices */
    }
}

.fit {
    display: flex;
    /* Enable Flexbox */
    justify-content: center;
    /* Center the image horizontally */
    align-items: center;
    /* Center the image vertically */
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    /* Ensure padding is included in the width calculation */
    width: 90%;
    /* Set the width of the container to 75% of its parent element */
    margin: 0 auto;
    /* Center the container horizontally */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fit-small {
    display: flex;
    /* Enable Flexbox */
    justify-content: center;
    /* Center the image horizontally */
    align-items: center;
    /* Center the image vertically */
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    /* Ensure padding is included in the width calculation */
    width: 50% !important;
    /* Set the width of the container to 75% of its parent element */
    margin: 0 auto;
    /* Center the container horizontally */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fit-small2 {
    display: flex;
    justify-content: center;
    /* Center the image horizontally */
    align-items: center;
    /* Center the image vertically */
    max-height: 80vh;
    /* Set maximum height relative to the viewport height */
    max-width: 60%;
    /* Ensure the width does not exceed the container width */
    padding: 20px;
    /* Add some padding around the image */
    box-sizing: border-box;
    /* Include padding in the width and height calculation */
    margin: 0 auto;
    /* Center the container horizontally */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.post-title {
    font-family: Arial, sans-serif !important;
}

.post-font2 {
    font-family: Arial, sans-serif !important;
}

.post-font p {
    font-family: Arial, sans-serif !important;
    font-size: 17px;
    /* Adjust this value to your desired font size */
    font-weight: 400;
    /* Adjust this value for desired font weight, e.g., 400 (normal), 500 (medium), 600 (semi-bold), etc. */
    line-height: 1.5;
    /* Adjust this value for desired space between rows; higher values increase space */
}

.strong,
b {
    font-weight: bold !important;
}

.hero2 {
    width: 100%;
    background-color: #306fa2 !important;
    /* Your desired color */
    padding-top: 5%;
}

.hero-contents2 {
    padding-left: 8%;
    padding-right: 5%;
    margin-top: -20px;
}

.hero-image {
    width: 90%;
    /* or you can specify any width you want */
    height: auto;
    /* try to make image crisper */
    box-shadow: 10px 10px 25px rgba(.1, .1, .1, .3) !important;
    /* Increased alpha value for darker shadow */
    box-sizing: border-box;
    /* Ensure padding is included in the width calculation */
    border-radius: 10px;
}

.hero-image2 {
    width: 100%;
    /* or you can specify any width you want */
    height: auto;
    /* try to make image crisper */
    box-shadow: 10px 10px 25px rgba(.1, .1, .1, .3) !important;
    /* Increased alpha value for darker shadow */
    box-sizing: border-box;
    /* Ensure padding is included in the width calculation */
    border-radius: 10px;
}

.hero-blog {
    width: 100% !important;
    /* or you can specify any width you want */

}

.shadow {
    box-shadow: 10px 10px 25px rgba(.1, .1, .1, .3) !important;
    /* try to make image crisper */
    box-sizing: border-box;
    /* Ensure padding is included in the width calculation */
    border-radius: 10px;
}

.post-img {
    display: flex;
    /* Enable Flexbox */
    justify-content: center;
    /* Center the image horizontally */
    align-items: center;
    /* Center the image vertically */
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    /* Ensure padding is included in the width calculation */
    width: 95%;
    /* Set the width of the container to 75% of its parent element */
    margin: 0 auto;
    /* Center the container horizontally */

}

.post-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    width: 90%;
    margin: 0 auto;

}

@media (min-width: 1000px) {
    .post-img-s {
        padding-left: 10%;
        padding-right: 10%;
    }

    .post-img-vs {
        width: 75%
    }

    .post-img-l {
        width: 120%;
        margin-left: -110px
    }
}

.dropdown-menu {
    display: none !important;
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
    padding-top: 5px !important;
    /* Increased padding for a larger box */
    padding-right: 25px !important;
    /* Increased padding for a larger box */
    padding-left: 25px !important;
    /* Increased padding for a larger box */
    padding-bottom: 5px !important;
    /* Increased padding for a larger box */
    width: 350px !important;
    /* Adjust width as necessary */
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block !important;
}

.dropdown-item {
    display: flex !important;
    align-items: center !important;
    padding: 10px !important;
    /* Increased padding for deeper items */
    font-size: 1rem !important;
    /* Larger content size */
}

.dropdown-item img {
    margin-right: 25px !important;
    width: 32px !important;
    /* Adjust as needed */
    height: 32px !important;
    /* Adjust as needed */
}

.dropdown-item-header {
    font-weight: bold !important;
}

.dropdown-item-text {
    display: block !important;
    font-size: 0.65rem !important;
    /* Smaller subtext size */
    color: #0b2436 !important;
}

.dropdown-item-subtext {
    display: block !important;
    font-size: 0.7rem !important;
    /* Smaller subtext size */
    color: #666666 !important;
    padding-top: 5px !important
}

.dropdown-divider {
    border-top: 1px solid #eaeaea !important;
    margin: 8px 0 !important;
    /* Spacing around the divider */
}

.nav-link:hover,
.navbar-nav .nav-item:hover>.nav-link {
    color: #306fa2 !important;
    /* New color for hover state */
}

.dropdown-menu .dropdown-item:hover .dropdown-item-header,
.dropdown-menu .dropdown-item:hover .dropdown-item-text,
.dropdown-menu .dropdown-item:hover .dropdown-item-subtext {
    color: #306fa2 !important;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    /* Increased padding for deeper items */
    font-size: 1rem;
    /* Larger content size */
    position: relative;
}

/* This assumes that you want the divider after every dropdown item except the last */
.dropdown-item:not(:last-child):after {
    content: "";
    display: block;
    border-bottom: 1px solid #eaeaea;
    position: absolute;
    bottom: 0;
    left: 10px;
    /* Match the horizontal padding of .dropdown-item */
    right: 10px;
    /* Match the horizontal padding of .dropdown-item */
}

.dropdown-item-wrapper {
    display: flex;
    align-items: center;
    /* Keep the flex alignment */
}

/* Remove padding from a tag and apply it to the content instead */
.dropdown-item {
    display: flex;
    align-items: center;
    /* Already flex, so just keep the alignment */
}

.dropdown-item-content {
    padding: 10px;
    /* Apply padding to the content, not the a tag */
    font-size: 1rem;
    /* Larger content size */
}

.logo-image {
    border-radius: 50%;
    /* Circular shape */
    object-fit: cover;
    /* Ensures the image covers the area without distortion */
    width: 32px;
    /* Adjust size as needed */
    height: 32px;
    /* Adjust size as needed */
}

/* Assuming 768px is the breakpoint for mobile views */
@media (max-width: 768px) {
    .navbar .dropdown-menu {
        display: none !important;
    }

    /* Optionally, you can also disable the hover effect */
    .navbar .nav-item:hover .dropdown-menu {
        display: none !important;
    }
}

.side-padded {
    padding-left: 5%;
    padding-right: 5%;
}

.story-img {
    margin-bottom: 10px;
    max-width: 40%;
    height: auto;
}

.icon-mb {
    margin-bottom: 20px;
}

.navbar-collapse.offcanvas-nav {
    top: 110px !important;
    /* Adjust this value based on your navbar's height */
}

.text-box {
    border: 2px dotted #306fa2;
    border-radius: 15px;
    padding: 40px;
    color: #306fa2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: -70px;
}

.content-wrapper2 {
    display: flex !important;
    justify-content: center !important;
    /* Centers horizontally */
    align-items: center !important;
    /* Centers vertically */
    height: 130px !important;
    /* Fixed height */
}

.blog-video {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    display: flex;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 1px;
    box-sizing: border-box;
    margin: 20px auto;
}

.blog-video-home {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 1px;
    box-sizing: border-box;
}

.home-video {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    box-sizing: border-box;
}

.home-video {
    position: relative;
    padding-top: 56.25%;
    /* Adjust this for your video's aspect ratio */
    overflow: hidden;
    /* This will prevent any overflow issues */
}

.home-video iframe,
.home-video img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.form-wrapper {
    padding-left: 25%;
    /* Adjust as needed */
    padding-right: 25%;
    /* Adjust as needed */
}

.form-wrapper-inference {
    padding-left: 10%;
    /* Adjust as needed */
    padding-right: 10%;
    /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Specific setup for the form wrapper */
.form-wrapper-email {
    margin: 0 auto;
    flex-direction: row;
    align-items: flex-start;
}

/* Consolidated common styles for the form wrapper and form fields */
.form-wrapper-email,
.hs-form-field,
.form-wrapper-email .input,
.form-wrapper-email .actions {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;
    flex-wrap: nowrap;
}

.hbspt-form {
    width: 80%;
}

/* Override for stacked forms to enforce flex behavior and alignment */
.hs-form.stacked,
.form-wrapper-email .hs-form.stacked {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

/* Shared field styles in stacked forms */
.hs-form.stacked .hs-form-field,
.form-wrapper-email .hs-form.stacked .hs-form-field {
    margin-right: 10px;
}

/* Individual styles for inputs and buttons in the form wrapper */
.form-wrapper-email .hs-input,
.form-wrapper-email .hs-button.primary.large {
    border-radius: 20px;
    padding: 10px;
    margin-right: 10px;
}

.form-wrapper-email .hs-input {
    border: 1px solid #ccc;
    flex: 5;
}

.hs_submit {
    width: 50%;
}

.form-wrapper-email .hs-button.primary.large {
    background-color: #306fa2;
    color: white;
    border: none;
    cursor: pointer;
    width: 120%;
}

/* Hidden labels and descriptions in email fields */
.hs_email label,
.hs_email .field-desc {
    display: none;
}

/* Simplified and corrected .hs-form-field, .input, and .actions styles */
.hs-form-field,
.input,
.actions {
    flex-direction: row;
    align-items: center;
}

.overview-video {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: relative;
    padding-top: 56.25%;
    /* Adjust this for your video's aspect ratio */
    overflow: hidden;
    /* This will prevent any overflow issues */
}

.overview-video iframe,
.overview-video img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.overview {
    width: 60%;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .overview {
        width: 95%;
        /* Adjust the width as needed for mobile devices */
    }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .blog-video {
        width: 90%;
        /* Adjust the width as needed for mobile devices */
    }
}

.blog-video-wrapper {
    margin: 40px auto;
    /* Space around the video */
}

.blog-video-wrapper-home {
    margin: 10px auto;
    /* Space around the video */
}

#video-container {
    position: relative;
}

#videoCover {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.invoke-wrap {
    padding-left: 20%;
    padding-right: 20%;
}

.invoke-wrap-s {
    padding-left: 10%;
    padding-right: 10%;
}

.invoke-wrap-vs {
    padding-left: 1%;
    padding-right: 1%;
}

.invoke-wrap-m {
    padding-left: 15%;
    padding-right: 15%;
}

@media (max-width: 768px) {
    .invoke-wrap {
        padding-left: 0%;
        padding-right: 0%;
    }

    .invoke-wrap-s {
        padding-left: 0%;
        padding-right: 0%;
    }
}

/* Default styles for desktop */
.responsive-iframe-container {
    position: relative;
    max-width: 512px;
    /* Adjust this as needed for your desktop layout */
    margin: 0 auto;
    /* Center align the container */
}

.responsive-iframe-container iframe {
    width: 100%;
    height: 421px;
    /* Adjust this as needed */
}

/* Responsive styles for mobile */
@media (max-width: 767px) {
    .responsive-iframe-container {
        padding-left: 5%;
        padding-right: 5%;
        width: 90%;
        /* Adjusted for mobile */
        padding-top: 56.25%;
        /* 16:9 Aspect Ratio */
        overflow: hidden;
    }

    .responsive-iframe-container iframe {
        position: absolute;
        top: 0;
        left: 5%;
        width: 90%;
        height: 100%;
    }
}

.underlined-text {
    text-decoration: underline;
    text-decoration-color: #e7a83d;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

.disclaimer {
    text-align: center;
    padding-left: 25%;
    padding-right: 25%;
    margin-bottom: -5%;
    margin-top: -20px;
    text-decoration-color: #e7a83d;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

@media (max-width: 768px) {
    .disclaimer {
        padding-left: 15%;
        padding-right: 15%;
    }
}

.pricing-caveat {
    text-align: center;
    padding-left: 18%;
    padding-right: 18%;
    margin-bottom: -5%;
    text-decoration-color: #e7a83d;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

.pricing-headers {
    text-decoration: underline;
    text-decoration-color: #E96A57;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

.pricing-wrapper2 .row {
    display: flex !important;
    justify-content: center !important;
}

.box2 {
    margin: 0 auto !important;
    /* Center the pricing box if the column is wider */
}

.blue {
    color: #0b2436 !important;
}

.orange {
    color: #e7a83d !important;
}

.light-blue {
    color: #306fa2 !important;
}

.white {
    color: #FFFFFF
}

.b-orange {
    background-color: #e7a83d !important
}

.b-light-blue {
    background-color: #306fa2 !important
}

.terminalizer {
    width: 90%;
    margin: auto;
    display: block;
    padding-left: 15%;
    padding-right: 15%;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .terminalizer {
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
    }
}

.arcadehome {
    width: 80%;
    margin: auto;
    display: block;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .arcadehome {
        width: 100%;
        /* Adjust the width as needed for mobile devices */
    }
}

.arcadehome>div {
    position: relative;
    padding-bottom: calc(67.56756756756756% + 41px);
    /* Original setting for larger screens */
}

/* Adjust for mobile screens */
@media (max-width: 768px) {
    .arcadehome>div {
        padding-bottom: 30;
        /* Remove the extra 41px on smaller screens */
    }
}

.home-gif {
    width: 115%;
    margin-left: -5%;
}


.home-gif-left {
    padding-left: 50px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .home-gif {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 1%;
        /* Adjust the width as needed for mobile devices */
    }

    .home-gif-left {
        padding-left: 0px;

    }
}


/* Media query for devices between 800px and 1100px */
@media (min-width: 769px) and (max-width: 1100px) {
    .home-gif {
        width: 100%;
        padding-right: 1%;
        /* Reset any other properties as needed */
    }
}

.function-mobile {
    margin-bottom: -90px;
}

@media (max-width: 1000px) {
    .function-mobile {
        padding-bottom: 18%;
        margin-bottom: 0px;
    }
}

.home-functions {
    width: 100%;
    margin: 0 auto;
    padding-left: 130px;
    margin-top: -25px;
}

.function-input {
    margin-top: 65px;
}

.mobile-margin {
    margin-top: -95px;
}

.function-images {
    margin-top: -35px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .home-functions {
        width: 100%;
        margin: 0 auto;
        padding-left: 0px;
        margin-top: -25px;
    }

    .function-input {
        margin-top: 0px;
    }

    .function-images {
        margin-top: 0px;
    }

    .mobile-margin {
        margin-top: -60px;
    }

    .mobile-margin2 {
        margin-top: -180px;
    }

    .negspacemobile {
        margin-top: -60px
    }
}


.logo-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* Ensures even spacing */
    align-content: space-around;
    max-width: 100%;
    /* Allows logo group to fill its container */
}

.logo-group figure {
    width: calc(50% - 20px);
    /* Adjust width to account for increased padding, if necessary */
    padding: 15px;
    /* Increased padding to effectively reduce logo display size */
    box-sizing: border-box;
    /* Ensures padding is included in the width calculation */
}

.quote-block {
    text-align: center;
    padding-top: 3%;
}

.quote {
    font-size: 1.2em;
    /* Slightly larger text for the quote */
    font-style: italic;
    margin-bottom: 10px;
}

.quote2 {
    font-size: 1.2em;
    /* Slightly larger text for the quote */
    margin-bottom: 10px;
}

.speaker {
    font-size: 1.1em;
    /* Slightly larger text for the speaker */
}

@media (max-width: 992px) {
    .logo-group figure {
        width: 100%;
        /* Full width logos on smaller screens */
        padding: 10px;
        /* Adjust back for smaller screens if needed */
    }
}

.invoke-blog {
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 2%
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .invoke-blog {
        padding-right: 0%;
        padding-left: 0%;
        padding-top: 0%
    }
}

.margin80 {
    margin-top: -110px;
    margin-bottom: -80px;
}

.width115 {
    width: 115%;
}

.width105 {
    width: 105%;
}

.pr-large-5 {
    padding-right: 5%
}

.lg-width-80 {
    width: 80%;
}

.width95 {
    width: 95%;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .mobile-up75 {
        margin-bottom: -75px !important;
    }

    .mobile-up50 {
        margin-top: -40px !important;
    }

    .margin80 {
        margin-top: -80px;
        margin-bottom: -50px;
    }

    .mobile-up25 {
        margin-bottom: -25px !important;
    }

    .width115 {
        width: 100%;
    }

    .width105 {
        width: 100%;
    }

    .pr-large-5 {
        padding-right: 2%
    }

    .mt-small-10 {
        margin-top: -10px;
    }

    .mt-small-20 {
        margin-top: -20px;
    }

    .mb-small-10 {
        margin-bottom: 10px;
    }

    .lg-width-80 {
        width: 100%;
    }

    .sm-padding-3 {
        padding-right: 3%
    }

    .width95 {
        width: 100%;
    }
}

/*-----------------------------------------------------------------------------------*/
/*  Padding/Margin Percentages
/*-----------------------------------------------------------------------------------*/

.top-container-margin {
    margin-top: -3% !important;
    margin-bottom: -3% !important;
}

.top-container-margin-video {
    margin-top: -8% !important;
    margin-bottom: -3% !important;
}

.top-container-padding {
    padding-top: 3% !important;
    padding-bottom: 3% !important;
}

.pl5 {
    padding-left: 5% !important;
}

.pl6 {
    padding-left: 6% !important;
}

.pl7 {
    padding-left: 7% !important;
}

.pl10 {
    padding-left: 10% !important;
}

.pr10 {
    padding-right: 10% !important;
}

.pl6 {
    padding-left: 6% !important;
}

.pr1 {
    padding-right: 1% !important;
}

.pr2 {
    padding-right: 2% !important;
}

.pr5 {
    padding-right: 5% !important;
}

.pr6 {
    padding-right: 6% !important;
}

.pt2 {
    padding-top: 2% !important;
}

.pt4 {
    padding-top: 4% !important;
}

.pb4 {
    padding-bottom: 4% !important;
}

.pb5 {
    padding-bottom: 5% !important;
}

.mtn3 {
    margin-top: -3% !important;
}

.mtn6 {
    margin-top: -6% !important;
}

.mtn8 {
    margin-top: -8% !important;
}

.mtn9 {
    margin-top: -9% !important;
}

.mtn10 {
    margin-top: -10% !important;
}

.mbn5 {
    margin-bottom: -5% !important;
}

.mbn8 {
    margin-bottom: -8% !important;
}

.mb4 {
    margin-bottom: 4% !important;
}

.mln2 {
    margin-left: -2% !important;
}


/*-----------------------------------------------------------------------------------*/
/*  Function types explorer
/*-----------------------------------------------------------------------------------*/

:root {
    --nyckel-color-darkblue: #0b2436;
    --nyckel-color-cta: #FBA804;
    --nyckel-color-blue-medium: #306fa2;
    --box-shadow-standard: 2px 2px 10px rgb(0 0 0 / 7%);
    --border-radius-standard: 5px;
}


@media(min-width: 900px) {
    #function-types {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center 100px;
    }
}

@media(min-width: 1100px) {
    .function-types-selector-column {
        padding-right: 25px;
    }
}

@media(min-width: 1300px) {
    .function-types-selector-column {
        padding-right: 100px;
    }
}

#function-types .info-tile {
    display: none;
}

#function-types .info-tile-selector {
    padding: 6px 6px 6px 6px;
    border: 1px solid #D3E2F7;
    display: inline-block;
    border-radius: 16px;
    margin-bottom: 14px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
}

#function-types .info-tile-selector2 {
    padding: 6px 6px 6px 6px;
    border: 1px solid #D3E2F7;
    display: inline-block;
    border-radius: 16px;
    margin-bottom: 14px;
    width: 105%;
    box-sizing: border-box;
    text-align: left;
}

#function-types .info-tile-selector2 {
    transition: all 0.3s ease;
    /* Smooth transition for hover effects */
    display: block;
    /* Ensure the link uses the full area of the grid column */
}

#function-types .info-tile-selector2:hover {
    background-color: #f0f8ff;
    /* Light blue background on hover */
    border-color: #306fa2;
    /* Change border color on hover */
    transform: scale(1.05);
    /* Slightly increase the size to make it stand out */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Soft shadow for depth */
}


#function-types .info-tile-selector.disabled {
    cursor: default;
}

#function-types .info-tile-selector.selected,
#function-types .info-tile-selector:hover:not(.disabled) {
    background-color: var(--nyckel-color-blue-medium);
    border-color: var(--nyckel-color-blue-medium);
    color: #fff;
}

#function-types .info-tile-selector.selected .selector-icon,
#function-types .info-tile-selector:hover:not(.disabled) .selector-icon {
    background: #47A1DB;
    border-color: #2790D5;
}

/* Base icons (light buttons) */
#function-types .info-tile-selector .dark {
    display: block;
}

#function-types .info-tile-selector .light {
    display: none;
}

/* Icons when selected or hovering (dark buttons): */
#function-types .info-tile-selector.selected .dark,
#function-types .info-tile-selector:hover:not(.disabled) .dark {
    display: none;
}

#function-types .info-tile-selector.selected .light,
#function-types .info-tile-selector:hover:not(.disabled) .light {
    display: block;
}

#function-types h3 {
    margin-bottom: 20px;
}

#function-types a {
    color: #193B68;
}

#function-types .selector-icon {
    width: 46px;
    height: 46px;

    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    background: #D0E7F6;
    border: 5px solid #E6F2FA;
    border-radius: 10px;
}

#function-types .selector-icon2 {
    width: 46px;
    height: 46px;

    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    background: #D0E7F6;
    border: 5px solid #E6F2FA;
    border-radius: 10px;
}

#function-types .selector-icon-inner {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#function-types .selector-icon-inner2 {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#function-types .info-tile-examples {
    padding: 0px;
    margin-left: 0px;
    margin-top: 0px;
    height: 350px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

@media(max-width: 900px) {
    .info-tile-examples {
        padding: 0px;
        margin-top: 0px;
    }
}

#function-types .selector-icon img {
    max-width: 30px;
}

#function-types .info-tile-example-image {
    box-shadow: var(--box-shadow-standard);
    border-radius: 8px;
    margin-bottom: 24px;
    width: 98%; // Slightly less than 100% in order to see dropshadow.
}

#function-types .function-types-back-button {
    font-size: 1rem;
    color: #357EDE;
    margin-top: 20px;
    cursor: pointer;
}

#function-types .function-types-back-button img {
    vertical-align: middle;
    width: 12px;
}

@media(min-width: 900px) {
    .function-explorer-steps-wrapper {
        display: none;
    }

    .function-explorer-title-divet {
        display: none;
    }

    .function-explorer-details p {
        margin-bottom: 0;
    }

    .function-explorer-details a {
        margin-bottom: 0;
    }
}

@media(max-width: 900px) {

    .function-tile.tile-standard-lessthan-900 {
        overflow: visible;
        padding-top: 34px;
    }

    #function-types-output,
    #function-types-detail {
        display: none;
    }

    #function-types .col-3 {
        flex-basis: 100%; // Overwrite the bootstrap default to use the full width.
    }

    #function-types .col-6 {
        flex-basis: 100%; // Overwrite the bootstrap default to use the full width.
    }

    .function-explorer-title {
        min-height: 10px;
        margin-top: -44px;
        position: relative;
    }

    .function-explorer-text {
        max-width: 250px;
        margin: 0 auto 40px auto;
    }

    .function-explorer-text p {
        color: #16488A;
        font-size: 1.1rem;
        line-height: 1.2;
    }

    #function-types h3 {
        display: inline-block;
        position: relative;
        padding: 2px 12px;
        background: var(--nyckel-color-blue-medium);
        color: #fff;
        border-radius: 4px;
    }

    #function-types h3 .function-explorer-title-label {
        position: relative;
        z-index: 2;
        color: #fff;
        font-size: .9rem;
        line-height: 2;
    }

    .function-explorer-title-divet {
        width: 12px;
        height: 12px;
        background: var(--nyckel-color-blue-medium);
        position: absolute;
        transform: rotate(45deg);
        margin-top: -5px;
        z-index: 1;
    }

    #function-types .info-tile-examples {
        height: 250px;
    }

    /* Step 1 - Left align title, Step 2, center align, etc... */
    .selected-1 .function-explorer-title {
        text-align: left;
    }

    .selected-1 h3 {
        margin-left: -10px;
    }

    .selected-1 .function-explorer-title-divet {
        left: 14px;
    }

    .selected-1 .steps-label-1 {
        display: none;
    }

    .selected-2 .function-explorer-title {
        text-align: center;
    }

    .selected-2 .function-explorer-title-divet {
        left: 50%;
        margin-left: -6px;
    }

    .selected-2 .steps-label-2 {
        display: none;
    }

    .selected-3 .function-explorer-title {
        text-align: right;
    }

    .selected-3 .function-explorer-title-divet {
        right: 14px;
    }

    .selected-3 h3 {
        margin-right: -10px;
    }

}

/* Function Types Steps */
.function-explorer-steps-wrapper {
    position: relative;
    min-height: 14px;
    padding-bottom: 30px;
    max-width: 350px;
    margin: 0 auto;
}

.function-explorer-steps-content {
    width: 80%;
    margin: 0 auto;
    height: 14px;
    position: relative;
}

.steps-dot {
    width: 14px;
    height: 14px;
    background: #99CBEB;
    border-radius: 50%;
    position: absolute;
}

.steps-dot-1 {
    left: -7px;
}

.steps-dot-2 {
    left: 50%;
    margin-left: -7px;
}

.steps-dot-3 {
    right: -7px;
}

.selected-1 .steps-dot-1 {
    background-color: #357ede;
}

.selected-2 .steps-dot-2 {
    background-color: #357ede;
}

.selected-3 .steps-dot-3 {
    background-color: #357ede;
}

.steps-line {
    border-bottom: 1px dashed #ACC9F1;
    height: 6px;
}

.steps-label {
    position: absolute;
    width: 70px;
    top: -24px;
    text-align: center;
    color: #16488A;
    font-size: .8rem;
}

#function-types .steps-label a {
    color: #16488A;
    font-size: .8rem;
}

.steps-label-1 {
    left: -35px;
}

.steps-label-2 {
    left: 50%;
    margin-left: -35px;
}

.steps-label-3 {
    right: -35px;
}

/* Function Types Steps */

.scrollbar-styled::-webkit-scrollbar {
    width: 12px;
    background: #E2EDF6;
}

.scrollbar-styled::-webkit-scrollbar-thumb {
    background: #C0DCF0;
}

.columns-fluid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.columns-fluid-new {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

@media (min-width:901px) {
    .hide-morethan-900 {
        display: none !important
    }
}

@media (max-width:900px) {
    .p-remove-900 {
        padding: 0
    }

    .hide-lessthan-900 {
        display: none !important
    }
}

@media(max-width: 900px) {
    .tile-standard-lessthan-900 {
        background: #fff;
        margin: 0 20px;
        border-radius: var(--border-radius-standard);
        overflow: hidden;
        box-shadow: var(--box-shadow-standard);
        height: 100%;
        box-sizing: border-box;
        position: relative;
        padding: 25px 25px 50px 25px;
        text-align: center;
        max-width: 350px;
        margin: 0 auto;
    }
}

/*-----------------------------------------------------------------------------------*/
/*  End function explorer. Start of comparison chart
/*-----------------------------------------------------------------------------------*/

.nyckel-comparison-chart table {
    table-layout: fixed;
    /* This makes your table respect the width settings */
    width: 100%;
    margin: 20px 0;
    font-family: Jost, sans-serif;
    font-size: 0.85rem;
    border-collapse: collapse;
    /* Ensures that the borders collapse into a single border */
}

.nyckel-comparison-chart th,
.nyckel-comparison-chart td {
    border: 1px solid #ddd;
    word-wrap: break-word;
    padding: 8px;
    text-align: center;
}

.nyckel-comparison-chart td:first-child {
    font-weight: bold;
    font-size: .9rem;
}

.nyckel-comparison-chart th:first-child,
.nyckel-comparison-chart td:first-child {
    width: 25%;
    /* Set the width of the first column */
}

.nyckel-comparison-chart th:nth-child(2),
.nyckel-comparison-chart td:nth-child(2) {
    width: 35%;
    /* Example: make the second column 20% of the table width */
}

.nyckel-comparison-chart th:nth-child(3),
.nyckel-comparison-chart td:nth-child(3) {
    width: 35%;
    /* Example: make the second column 20% of the table width */
}

/* Remove individual cell borders for the third column */
.nyckel-comparison-chart th:nth-child(4),
.nyckel-comparison-chart td:nth-child(4) {
    border: 1px solid #ddd;
    width: 40%;
}

/* Add blue border for the entire third column */
.nyckel-comparison-chart th:nth-child(4),
.nyckel-comparison-chart td:nth-child(4) {
    border-left: 2px solid #306fa2;
    border-right: 2px solid #306fa2;
}

/* For the first cell of the third column, add top border */
.nyckel-comparison-chart th:nth-child(4) {
    border-top: 2px solid #306fa2;
}

/* For the last cell of the third column, add bottom border */
.nyckel-comparison-chart tr:last-child td:nth-child(4) {
    border-bottom: 2px solid #306fa2;
}

.nyckel-comparison-chart .icon-check {
    width: 32px;
    height: 32px;
    fill: #4CAF50;
}

.nyckel-comparison-chart .icon-cross {
    width: 32px;
    height: 32px;
    fill: #F44336;
}


.content-invoke-left {
    padding-left: 6%;
    margin-bottom: -105px;
    margin-right: -70px;
}

@media (max-width:768px) {
    .content-invoke-left {
        padding-left: 0%;
        margin-bottom: 0;
        margin-right: 0px;
    }
}

.content-invoke-right {
    margin-left: -60px;
}

.hospital-invoke {
    margin-top: 75px
}

@media (max-width:768px) {
    .content-invoke-right {
        margin-left: 0px;
    }

    .hospital-invoke {
        margin-top: 0px;
    }
}

.content-invoke-right-top {
    margin-top: 60px;
}

.content-invoke-right-top-text {
    margin-top: 100px;
}

@media (max-width:768px) {
    .content-invoke-right-top {
        margin-top: 0px;
    }
}

.content-hero {
    padding-left: 70px;
}

@media (max-width:768px) {
    .content-hero {
        padding-left: 20px;
        margin-bottom: 50px;
    }
}

.usageramp td {
    padding: 0 20px;
}

.usageramp th {
    padding: 0 20px;
}

.usageramp h3 {
    padding: 0 20px;
}

.nyckel-comparison-pricing-chart {
    display: flex;
    justify-content: center;
}

.nyckel-comparison-pricing-chart table {
    width: 70%;
    margin: 20px 0;
    font-family: Jost, sans-serif;
    font-size: 0.75rem;
    border-collapse: collapse;
    table-layout: fixed;
}

.nyckel-comparison-pricing-chart th,
.nyckel-comparison-pricing-chart td {
    border: 1px solid #ddd;
    word-wrap: break-word;
    padding: 8px;
    text-align: center;
}

.nyckel-comparison-pricing-chart th:first-child,
.nyckel-comparison-pricing-chart td:first-child {
    width: 25%;
}

.nyckel-comparison-pricing-chart th:nth-child(2),
.nyckel-comparison-pricing-chart td:nth-child(2) {
    width: 35%;
}

.nyckel-comparison-pricing-chart th:nth-child(3),
.nyckel-comparison-pricing-chart td:nth-child(3) {
    border: 1px solid #ddd;
    width: 40%;
}

.nyckel-comparison-pricing-chart .icon-check {
    width: 32px;
    height: 32px;
    fill: #4CAF50;
}

.nyckel-comparison-pricing-chart .icon-cross {
    width: 32px;
    height: 32px;
    fill: #F44336;
}

#result {
    font-weight: bold;
    margin: 0;
    text-align: center;
    width: 100%;
    /* Ensure the text spans the entire width of the container */
    height: 100%;
    /* Ensure the text spans the entire height of the container */
    display: flex;
    align-items: center;
    justify-content: center;
}

.ramp-invokes {
    margin-left: 30px;
    margin-right: -70px;
    padding-left: 5px;
    padding-right: 5px;
}

.ramp-samples {
    margin-left: -45px;
    margin-right: -70px;
    padding-left: 5px;
    padding-right: 5px;
}

.ramp-button {
    margin-bottom: 0rem;
    margin-left: -30px;
    margin-right: 10px;
}

.ramp-button-column {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {

    .ramp-invokes {}

    .ramp-samples {}

    .ramp-button {}

    .ramp-button-column {}
}

@media only screen and (max-width: 768px) {
    .hide-on-mobile {
        display: none;
    }
}